import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { getUserData } from "../../../../utils/helperFunctions";
import getEndpoints from "../../../../../src/config/endpoints";
import axios from "axios";
import "./index.scss";
import Select from "react-select";
import { useLocation } from "react-router-dom";
import ConfirmationPrompt from "../confirmationPrompt";
const MemberListModal = (props) => {
  const profileData = getUserData();
  const location = useLocation();
  const [modalBoxShow, setModalBoxShow] = useState(props.setDisplay);
  const [trigger, setTrigger] = useState(0);
 
  const [memberId, setMemberId] = useState(null);
  const [usersData, setUsersData] = useState(null);

  const [errorState, setErrorState] = useState(null);
  const [confirmButtonStatus, setConfirmButtonStatus] = useState(true);
  const [name, setName] = useState(null);
  const [nameOptions, setNameOptions] = useState([]);

  const [lastName, setLastName] = useState(null);
  const [lastNameOptions, setLastNameOptions] = useState([]);

  const [email, setEmail] = useState(null);
  const [emailOptions, setEmailOptions] = useState([]);

  const [contact, setContact] = useState(null);
  const [contactOptions, setContactOptions] = useState([]);

  const [address, setAddress] = useState(null);
  const [addressOptions, setAddressOptions] = useState([]);

  const [shares, setShares] = useState(null);
  const { shares_avail } = location.state;
  const hideTrigger = props.hideTriggerFunc;

  const [modalShow, setModalShow] = useState(false);
  const [bgClr, setBgClr] = useState("bgClr");
  const nameOptionFunc = () => {
    const nameOptions = [];
    if (usersData !== null) {
      usersData.forEach((userData) => {
        if (userData.first_name !== null) {
          nameOptions.push({
            value: userData.user_id,
            label: userData.first_name,
          });
        }
      });
      setNameOptions(nameOptions);
    }
  };
  const LastnameOptionFunc = () => {
    const LastnameOptions = [];
    if (usersData !== null) {
      usersData.forEach((userData) => {
        if (userData.last_name !== "") {
          LastnameOptions.push({
            value: userData.user_id,
            label: userData.last_name,
          });
        }
      });
      setLastNameOptions(LastnameOptions);
    }
  };

  const emailOptionFunc = () => {
    const emailOptions = [];
    if (usersData !== null) {
      usersData.forEach((userData) => {
        if (userData.email !== null) {
          emailOptions.push({
            value: userData.user_id,
            label: userData.email,
          });
        }
      });
      setEmailOptions(emailOptions);
    }
  };

  const contactOptionFunc = () => {
    const contactOptions = [];
    if (usersData !== null) {
      usersData.forEach((userData) => {
        if (userData.phone_no !== null) {
          contactOptions.push({
            value: userData.user_id,
            label: userData.phone_no,
          });
        }
      });
      setContactOptions(contactOptions);
    }
  };
  const addressOptionFunc = () => {
    const addressOptions = [];
    if (usersData !== null) {
      usersData.forEach((userData) => {
        if (userData.address !== null) {
          addressOptions.push({
            value: userData.user_id,
            label: userData.address,
          });
        }
      });
      setAddressOptions(addressOptions);
    }
  };
  const triggerShareChange = (share) => {
    setShares(share.target.value);
    if (share.target.value > shares_avail) {
      setErrorState(["Please enter Share Value less than " + shares_avail]);
      setConfirmButtonStatus(true);
    } else if (
      memberId == null ||
      name == null ||
      lastName == null ||
      email == null ||
      contact == null ||
      address == null ||
      share.target.value == null ||
      share.target.value == ""
    ) {
      setConfirmButtonStatus(true);
      setErrorState(["Please all fields"]);
    } else {
      setErrorState(null);
      setConfirmButtonStatus(false);
    }
  };
  const triggerAutoPopulate = (obj) => {
   
    if (obj.value !== null) {
      setMemberId(obj.value);
    }
    //first name
    let NameResult = nameOptions.find((Name) => Name.value === obj.value);
    if (NameResult !== null) {
      setName(NameResult);
    }

    //last name
    let lastNameResult = lastNameOptions.find(
      (lastName) => lastName.value === obj.value
    );
    if (lastNameResult !== null) {
      setLastName(lastNameResult);
    }

    //email
    let emailResult = emailOptions.find((email) => email.value === obj.value);
    if (emailResult !== null) {
      setEmail(emailResult);
    }

    //contact
    let contactResult = contactOptions.find(
      (contact) => contact.value === obj.value
    );
    if (contactResult !== null) {
      setContact(contactResult);
    }

    //address
    let addressResult = addressOptions.find(
      (address) => address.value === obj.value
    );
    if (addressResult !== null) {
      setAddress(addressResult);
    }
    if (
      memberId == null ||
      name == null ||
      lastName == null ||
      email == null ||
      contact == null ||
      address == null ||
      shares == null ||
      shares == ""
    ) {
      setConfirmButtonStatus(true);
    } else {
      setConfirmButtonStatus(false);
    }
    setTrigger(trigger + 1);
  };
  const triggerMemberAdd = async () => {
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    formData.append("member_id", memberId);
    formData.append("cask_id", props.caskData);
    formData.append("shares", shares);

    try {
      const endpoint = getEndpoints().groupadmin.member.add;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      setModalBoxShow(false);
      setTrigger(trigger + 1);
     
      if (response.data.success) {
         modalTriggerHide();
         hideTriggerFunc();
       }
      return response;
    } catch (e) {
      return e.response || e;
    }
    
  };
  const triggerMemberList = async () => {
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    try {
      const endpoint = getEndpoints().groupadmin.member.popup_trigger;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      setUsersData(response.data.userData);
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  const funcTrigger = () => {
    nameOptionFunc();
    LastnameOptionFunc();
    emailOptionFunc();
    contactOptionFunc();
    addressOptionFunc();
  };
  const hideTriggerFunc = () => {
    setMemberId(null);
    setName(null);
    setLastName(null);
    setEmail(null);
    setContact(null);
    setAddress(null);
    setShares(null);
    setErrorState(null)
    setConfirmButtonStatus(true);
    hideTrigger();
  };
  const modalTriggerHide = () => {
    setModalShow(false);
  };
  useEffect(() => {
    setModalBoxShow(props.setDisplay);
    triggerMemberList();
    funcTrigger();
  }, [props, trigger]);
  return (
    <>
    <ConfirmationPrompt
        show={modalShow}
        confirmFunc={triggerMemberAdd}
        close={modalTriggerHide}
      />
      <Modal show={modalBoxShow} className="ModalOuterDiv">
        <Modal.Body>
          <div className="MemberModalBody">
            <div className="memberModalBodyMain">
              <div className="memberModalHeadingAdd">Member Details</div>
              {errorState &&
                errorState.map((error) => {
                  return <div className="errorMsg">{error}</div>;
                })}
            </div>
            <div className="memberInputFields" key={trigger}>
              <div className="memberInputRow">
                <div className="memberInputRowLabel">First Name:</div>
                <div className="memberInputRow">
                  <Select
                    className="memberSelect"
                    options={nameOptions}
                    onChange={(e) => {
                      triggerAutoPopulate(e);
                    }}
                    defaultValue={name}
                  />
                </div>
              </div>
              <div className="memberInputRow">
                <div className="memberInputRowLabel">Last Name:</div>
                <div className="memberInputRow">
                  <Select
                    className="memberSelect"
                    options={lastNameOptions}
                    onChange={(e) => {
                      triggerAutoPopulate(e);
                    }}
                    defaultValue={lastName}
                  />
                </div>
              </div>
              <div className="memberInputRow">
                <div className="memberInputRowLabel">Email ID:</div>
                <div>
                  <Select
                    className="memberSelect"
                    options={emailOptions}
                    onChange={(e) => {
                      triggerAutoPopulate(e);
                    }}
                    defaultValue={email}
                  />
                </div>
              </div>
              <div className="memberInputRow">
                <div className="memberInputRowLabel">Contact:</div>
                <div>
                  <Select
                    className="memberSelect"
                    options={contactOptions}
                    onChange={(e) => {
                      triggerAutoPopulate(e);
                    }}
                    defaultValue={contact}
                  />
                </div>
              </div>
              <div className="memberInputRow">
                <div className="memberInputRowLabel">Address:</div>
                <div>
                  <Select
                    className="memberSelect"
                    options={addressOptions}
                    onChange={(e) => {
                      triggerAutoPopulate(e);
                    }}
                    defaultValue={address}
                  />
                </div>
              </div>
              <div className="memberInputRow">
                <div className="memberInputRowLabel">Shares:</div>
                <div>
                  <input
                    type="number"
                    required
                    onChange={(e) => {
                      triggerShareChange(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="btnRowError">
              <button
                className="btnRedUpload"
                onClick={() => setModalShow(true)}
                disabled={confirmButtonStatus}
              >
                SUBMIT
              </button>
              <button className="btnRedCancel" onClick={hideTriggerFunc}>
                CANCEL
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default MemberListModal;
