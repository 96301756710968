import React, { useState, useEffect } from "react";
import ProfileCard from "../../../components/Shared/ProfileCard/ProfileCard";
import "./index.scss";
import burgerMenuIcon from "../../../assets/images/burgerMenuIcon.svg";
import { Button } from "react-bootstrap";
import getEndpoints from "../../../../src/config/endpoints";
import axios from "axios";
import {
  getToken,
  getUserData,
  setUserData,
} from "../../../utils/helperFunctions";
const Stripe = () => {
  const [openSidebar, setOpenSidebar] = useState(true);
  const token = getToken();
  const profileData = getUserData();
  const createAcc = async () => {
    try {
      const endpoint = getEndpoints().stripe.createConnectAccount;
      const response = await axios.get(endpoint, {
        headers: { token: profileData.token },
      });

      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  const payouts = async () => {
    try {
      const endpoint = getEndpoints().stripe.add_bank;
      const response = await axios.get(endpoint, {
        headers: { token: profileData.token },
      });

      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  useEffect(() => {
    // dummyFunc();
  }, []);
  return (
    <>
      <div className="mainContainerOuter">
        <main
          id="mainDashboard"
          className={openSidebar ? "main" : "mainContainer"}
        >
          <div className="page-header">
            <div className="pagetitle">
              <img
                src={burgerMenuIcon}
                className="toggle-sidebar-btn"
                onClick={() => setOpenSidebar(!openSidebar)}
              />
              <h1>Stripe Test</h1>
            </div>
            <ProfileCard />
          </div>

          <section className="section dashboard">
            <>
              <Button variant="primary" onClick={createAcc}>
                Connected User Create
              </Button>
              <Button variant="warning" onClick={payouts}>
                Payout
              </Button>{" "}
            </>
          </section>
        </main>
      </div>
    </>
  );
};

export default Stripe;
