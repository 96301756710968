import React, { useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Auth from "../components/Auth";
import GroupAdminDashboard from "../pages/GroupAdmin/GroupAdminDashboard";
import GroupAdminWallet from "../pages/GroupAdmin/GroupAdminWallet";
import GroupAdminWalletWithdraw from "../pages/GroupAdmin/GroupAdminWalletWithdraw";
import GroupAdminWalletDeposit from "../pages/GroupAdmin/GroupAdminDeposit";
import GATransactionsMain from "../pages/GroupAdmin/GroupAdminTransaction";
import GroupAdminMemberList from "../pages/GroupAdmin/GroupAdminMemberList";
import GroupAdminMarketPublic from "../pages/GroupAdmin/GroupAdminMarketPublic";
import GroupAdminMarketPrivate from "../pages/GroupAdmin/GroupAdminMarketPrivate";
import GroupAdminBuyCask from "../pages/GroupAdmin/GroupAdminBuyCask";
import GroupAdminBuyCaskMultiple from "../pages/GroupAdmin/GroupAdminBuyCaskMultiple";
import GroupAdminSellCask from "../pages/GroupAdmin/GroupAdminSellCask";
import GroupAdminCaskRequest from "../pages/GroupAdmin/GroupAdminCaskRequest";
import GAMembershipVotingCreate from "../pages/GroupAdmin/GroupAdminMembershipVoting/CreatePoll/index";
import GAMembershipVotingView from "../pages/GroupAdmin/GroupAdminMembershipVoting/ViewPoll";
import GrpAdmSideBar from "../components/GrpAdmSideBar";
import IndividualAdminDashboard from "../pages/IndividualAdmin/IndividualAdminDashboard";

const GroupAdminRoutes = () => {
  const location = useLocation();
  const [openSidebar, setOpenSidebar] = useState(true);

  return (
    <>
      <GrpAdmSideBar openSidebar={openSidebar} />

      <Routes>
        <Route
          path="/"
          element={
            <Auth>
              <GroupAdminDashboard />
            </Auth>
          }
        />
        <Route
          path="/wallet"
          element={
            <Auth>
              <GroupAdminWallet />
            </Auth>
          }
        />
        <Route
          path="/wallet/withdraw"
          element={
            <Auth>
              <GroupAdminWalletWithdraw />
            </Auth>
          }
        />
        <Route
          path="/wallet/deposit"
          element={
            <Auth>
              <GroupAdminWalletDeposit />
            </Auth>
          }
        />
        <Route
          path="/transactions"
          element={
            <Auth>
              <GATransactionsMain />
            </Auth>
          }
        />
        <Route
          path="/memberlist"
          element={
            location.state !== null ? (
              <Auth>
                <GroupAdminMemberList />
              </Auth>
            ) : (
              <Navigate replace to={"/groupadmin/"} />
            )
          }
        />
        <Route
          path="/marketplace/public"
          element={
            <Auth>
              <GroupAdminMarketPublic />
            </Auth>
          }
        />
        <Route
          path="/marketplace/private"
          element={
            <Auth>
              <GroupAdminMarketPrivate />
            </Auth>
          }
        />
        <Route
          path="/marketplace/buycask_multiple/private"
          element={
            location.state !== null ? (
              <Auth>
                <GroupAdminBuyCaskMultiple />
              </Auth>
            ) : (
              <Navigate replace to={"/groupadmin/"} />
            )
          }
        />
        <Route
          path="/marketplace/buycask/:type"
          element={
            location.state !== null ? (
              <Auth>
                <GroupAdminBuyCask />
              </Auth>
            ) : (
              <Navigate replace to={"/groupadmin/"} />
            )
          }
        />
        <Route
          path="/marketplace/sellcask"
          element={
            location.state !== null ? (
              <Auth>
                <GroupAdminSellCask />
              </Auth>
            ) : (
              <Navigate replace to={"/groupadmin/"} />
            )
          }
        />
        <Route
          path="/cask-request"
          element={
            <Auth>
              <GroupAdminCaskRequest />
            </Auth>
          }
        />
        <Route
          path="/create-poll"
          element={
            <Auth>
              <GAMembershipVotingCreate />
            </Auth>
          }
        />
        <Route
          path="/view-poll"
          element={
            <Auth>
              <GAMembershipVotingView />
            </Auth>
          }
        />
        <Route exact path="*" element={<h1>Page Not Found</h1>} />
      </Routes>
    </>
  );
};

export default GroupAdminRoutes;
