import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Logo from "../../../assets/images/logo.png";
import { PriceComponent, getUserData } from "../../../utils/helperFunctions";
import "./index.scss";
const InvoiceBroker = (props) => {
  const profileData = getUserData();
  let [showModal, setShowModal] = useState(props.setDisplay);
  const invoiceSet = props.invoiceData;
  const setHide = props.onHide;
  const totalData = props.totalData;
  const transAmount = props.transAmount;
  const date = props.date;
  const walletAction = props.walletAction;
  const [subTotal, setSubTotal] = useState(0);
  const [transactionFee, setTransactionFee] = useState(0);
  const [platformFee, setPlatformFee] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const amountData = () => {
    let sumTotal = 0;
    if (walletAction === "Sell"){
      if (invoiceSet !== null) {
        Object.keys(invoiceSet).map((index) => {
          if (Number(invoiceSet[index].cask_price) !== "") {
            sumTotal = sumTotal + Number(invoiceSet[index].cask_price);
          }
        });
        setSubTotal(sumTotal);
        setPlatformFee(sumTotal * (5 / 100)); //set 5% platform fee
        setTransactionFee(sumTotal * (5 / 100)); //set 5% transaction fee
        setTotalAmount(sumTotal + sumTotal * (5 / 100) + sumTotal * (5 / 100));
      }
    }
  };

  useEffect(() => {
    setShowModal(props.setDisplay);
    amountData();
  }, [props]);

  return (
    <>
      <Modal show={showModal}>
        <Modal.Body bsPrefix="invoiceBrkr">
          <div className="invoiceMainBrkr">
            <div className="invoiceHeadBrkr">
              <div className="logoInvoiceBrkr">
                <img src={Logo} className="img-fluid" />
              </div>
              <div className="address">
                <h6>Bit Cask</h6>
                <p>160 Robinson Road, #14-04</p>
                <p>Singapore Business Federation Centre</p>
                <p>Singapore</p>
                <p>068914</p>
              </div>
            </div>
            <div className="invoiceBodyBrkr">
              <div className="invoice-details1">
                <h4>INVOICE</h4>
              </div>
              <div className="invoice-details">
                <div className="invoice-left">
                  <div className="invoice-content">
                    <h6>Paid By :</h6>
                    <p>
                      {profileData.first_name + " " + profileData.last_name}
                    </p>
                  </div>
                  <div className="invoice-content">
                    <h6>Address :</h6>
                    <p>10 Dover Drive Singapore 138683</p>
                  </div>
                </div>
                <div className="invoice-center">
                  <div className="invoice-content">
                    <h6>Invoice Number :</h6>
                    <p>BC-INV/2545-125/210</p>
                  </div>
                </div>
                <div className="invoice-right">
                  <div className="invoice-content1">
                    <h6>Date :</h6>
                    <p>{date}</p>
                  </div>
                  <div className="invoice-content1">
                    <h6>Payment Method :</h6>

                    <p>WALLET</p>
                  </div>
                </div>
              </div>
              <div className="invoice-bill">
                <div className="invoice-table-container">
                  <table className="invoice-table">
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {walletAction !== "Withdraw" &&
                        walletAction !== "Deposit" &&
                        invoiceSet &&
                        Object.keys(invoiceSet).map((index) => {
                          return (
                            <tr>
                              <td>
                                {invoiceSet[index].distillery &&
                                  invoiceSet[index].distillery +
                                    ", " +
                                    invoiceSet[index].casktype}
                                <div className="description">
                                  {invoiceSet[index].casktype && "Entire Cask"}
                                </div>
                              </td>
                              <td>{invoiceSet[index].distillery && "1"}</td>
                              <td>
                                {invoiceSet[index].cask_price &&
                                  "£" +
                                    invoiceSet[
                                      index
                                    ].cask_price.toLocaleString()}
                                <div className="description1">
                                  {invoiceSet[index].casktype_sell}
                                </div>
                              </td>
                              <td>
                                {" "}
                                {invoiceSet[index].cask_price &&
                                  " £" +
                                    invoiceSet[
                                      index
                                    ].cask_price.toLocaleString()}
                              </td>
                            </tr>
                          );
                        })}
                      {walletAction === "Withdraw" ||
                      walletAction === "Deposit" ? (
                        <tr>
                          <td>{walletAction}</td>
                          <td></td>
                          <td></td>
                          <td>{transAmount}</td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </table>
                  <img src={Logo} alt="" className="invoice-logo" />
                </div>
                {walletAction !== "Withdraw" && walletAction !== "Deposit" ? (
                  <div className="invoice-total">
                    <div>
                      <p>Subtotal</p>
                      {/* <h6>£{subTotal.toLocaleString()}</h6> */}
                      <h6>£{PriceComponent(subTotal)}</h6>
                    </div>
                    <div>
                      <p>Platform Fee (5%)</p>
                      <h6>£{PriceComponent(platformFee)}</h6>
                    </div>
                    <div>
                      <p>Transaction Fee (5%)</p>
                      <h6>£{PriceComponent(transactionFee)}</h6>
                    </div>
                    <div>
                      <p>Discount</p>
                      <h6>£0.00</h6>
                    </div>
                    <div>
                      <p>TOTAL AMOUNT</p>
                      <h6>£{PriceComponent(totalAmount)}</h6>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="invoice-footer">
              <button className="red-btn" onClick={() => setHide()}>
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default InvoiceBroker;
