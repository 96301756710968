import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getToken, getUserData } from "../../../utils/helperFunctions";
import authenticationManagementAPIs from "../../../services/authentication.service";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./index.scss";
import walletImage from "../../../assets/images/cask_request.PNG";
import DownArrow from "../../../assets/images/down.png";
const IACaskRequest = () => {
  const [budget, setBudget] = useState("");
  const [budgetInt, setBudgetInt] = useState("");
  const [budgetError, setBudgetError] = useState("");
  const [purpose, setPurpose] = useState("");
  const [purposeError, setPurposeError] = useState("");
  const [ageRangeTo, setAgeRangeTo] = useState("");
  const [ageRangeToError, setAgeRangeToError] = useState("");
  const [ageRangeFrom, setAgeRangeFrom] = useState("");
  const [ageRangeFromError, setAgeRangeFromError] = useState("");
  const [holdPeriod, setHoldPeriod] = useState("Short Term");
  const [holdPeriodError, setHoldPeriodError] = useState("");
  const [distillery, setDistillery] = useState("");
  const [distilleryError, setDistilleryError] = useState("");
  const [caskType, setCaskType] = useState("");
  const [caskTypeError, setCaskTypeError] = useState("");
  const [caskSize, setCaskSize] = useState("");
  const [caskSizeError, setCaskSizeError] = useState("");
  const [key, setKey] = useState("");
  const [caskTypeResponse, setCaskTypeResponse] = useState("");
  const [noOfCasks, setNoOfCask] = useState("");
  const [noOfCasksError, setNoOfCaskError] = useState("");
  const userData = getUserData();
  const user_id = JSON.stringify(userData.user_id);
  const [active, setActive] = useState(true);
  const [activeClassIndividaul, setActiveClassIndividual] = useState(true);
  const [activeClassPortfolio, setActiveClassPortfolio] = useState(false);
  const [error, setError] = useState("");

  const validateBudget = (budget) => {
    setError("");
    var pattern = /(^$)|(^[,.0-9]+$)/;
    if (pattern.test(budget.target.value) == true) {
      setBudgetInt(budget.target.value);
      setBudgetError("");
      setBudget(budget.target.value.replace(/\,/g, ""));
      
    } else {
      setBudgetError("* Please input a valid Budget");
    }
  };

  const validateField = (e) => {
    setError("");
    if (e.target.name == "purpose") {
      var pattern = /^[\a-zA-Z,!? ]*$/;
      if (pattern.test(e.target.value) == true) {
        setPurpose(e.target.value);
        setPurposeError("");
      } else {
        setPurposeError("* Please input a valid Purpose");
      }
    } else if (e.target.name == "distillery") {
      var pattern = /^[\a-zA-Z,!? ]*$/;
      if (pattern.test(e.target.value) == true) {
        setDistillery(e.target.value);
        setDistilleryError("");
      } else {
        setDistilleryError("* Please input a valid Distillery");
      }
    } else if (e.target.name == "caskSize") {
      var pattern = /(^$)|(^[0-9]+$)/;
      if (pattern.test(e.target.value) == true) {
        setCaskSize(e.target.value);
        setCaskSizeError("");
        setKey("individual");
      } else {
        setCaskSizeError("* Please input a valid Cask size");
      }
    } else if (e.target.name == "ageRangeFrom") {
      var pattern = /(^$)|(^[0-9]+$)/;
      if (pattern.test(e.target.value) == true) {
        if (e.target.value.length <= 3) {
          setAgeRangeFrom(e.target.value);
          setAgeRangeFromError("");
        } else {
          setAgeRangeFromError("* Please input a valid age range");
        }
      }
    } else if (e.target.name == "ageRangeTo") {
      var pattern = /(^$)|(^[0-9]+$)/;
      if (pattern.test(e.target.value) == true) {
        if (e.target.value.length <= 3) {
          setAgeRangeTo(e.target.value);
          setAgeRangeToError("");
        } else {
          setAgeRangeToError("* Please input a valid age range");
        }
      }
    } else if (e.target.name == "noOfCasks") {
      var pattern = /(^$)|(^[0-9]+$)/;
      if (pattern.test(e.target.value) == true) {
        setNoOfCask(e.target.value);
        setKey("portfolio");
        setCaskSize("");
        setNoOfCaskError("");
      } else {
        setNoOfCaskError("* Please input a valid input");
      }
    }
  };

  const validateHoldPeriod = (holdPeriod) => {
    var pattern = /^[.0-9a-zA-Z\s,-]+$/;
    if (pattern.test(holdPeriod.target.value) == true) {
      setHoldPeriod(holdPeriod.target.value);
    }
  };
  const handleSaveIndividual = async (e) => {
    e.preventDefault();
    if (
      budget !== "" &&
      purpose !== "" &&
      ageRangeFrom !== "" &&
      ageRangeTo !== "" &&
      holdPeriod !== "" &&
      distillery !== "" &&
      caskType !== "" &&
      caskSize !== "" &&
      key !== ""&&
      budgetError === "" &&
      purposeError  === "" &&
      ageRangeFromError === "" &&
      ageRangeToError  === "" &&
      holdPeriodError === "" &&
      distilleryError  === "" &&
      caskTypeError === "" &&
      caskSizeError  === ""
    ) {
     
      const token = getToken();

      const body = {
        budget,
        purpose,
        ageRangeFrom,
        ageRangeTo,
        holdPeriod,
        distillery,
        caskType,
        caskSize,
        key,
        user_id,
      };
      const responseData =
        await authenticationManagementAPIs.caskRequestIndividual(token, body);
      if (responseData.data.success == "True") {
        setDefault();
      }
    }else{
      setError("* Please provide all inputs")
    }
  };
  const handleSavePortfolio = async (e) => {
    e.preventDefault();
    if (
      budget !== "" &&
      purpose !== "" &&
      ageRangeFrom !== "" &&
      ageRangeTo !== "" &&
      holdPeriod !== "" &&
      distillery !== "" &&
      caskType !== "" &&
      noOfCasks !== "" &&
      key !== ""&&
      budgetError === "" &&
      purposeError  === "" &&
      ageRangeFromError === "" &&
      ageRangeToError  === "" &&
      holdPeriodError === "" &&
      distilleryError  === "" &&
      caskTypeError === "" &&
      noOfCasksError  === ""
    ) {
      const token = getToken();
      const body = {
        budget,
        purpose,
        ageRangeFrom,
        ageRangeTo,
        holdPeriod,
        distillery,
        caskType,
        key,
        user_id,
        noOfCasks,
      };
      const responseData =
        await authenticationManagementAPIs.caskRequestIndividual(token, body);
      if (responseData.data.success == "True") {
        setDefault();
      }
    }else{
      setError("* Please provide all inputs")
    }
  };
  const handleSurprisePortfolio = async (e) => {
    e.preventDefault();
    if (budget !== "" && purpose !== "" && noOfCasks !== "") {
      setKey("portfolio");
      const token = getToken();
      const body = {
        budget,
        purpose,
        key,
        user_id,
        noOfCasks,
      };
      const responseData =
        await authenticationManagementAPIs.caskRequestIndividualSurpriseMe(
          token,
          body
        );
      if (responseData.data.success == "True") {
        setDefault();
      }
    }else{
      setError("* Please provide budget, purpose and hold period")
    }
  };
  const handleSurpriseIndividual = async (e) => {
    e.preventDefault();
    if (budget !== "" && purpose !== "" && holdPeriod !== "") {
      setKey("individual");
      const token = getToken();
      const body = {
        budget,
        purpose,
        holdPeriod,
        key,
        user_id,
      };
      const responseData =
        await authenticationManagementAPIs.caskRequestIndividualSurpriseMe(
          token,
          body
        );
      if (responseData.data.success == "True") {
        setDefault();
      }
    }else{
      setError("* Please provide budget, purpose and hold period")
    }
  };

  const fetchCaskType = async () => {
    const token = getToken();
    const body = { user_id };
    const responseData = await authenticationManagementAPIs.caskType(
      token,
      body
    );
    setCaskTypeResponse(responseData.data);
  };

  const setDefault = (e) => {
    if (e == "home") {
      setActiveClassIndividual(true);
      setActiveClassPortfolio(false);
    } else {
      setActiveClassPortfolio(true);
      setActiveClassIndividual(false);
    }
    setBudget("");
     setBudgetInt("");
    setPurpose("");
    setDistillery("");
    setHoldPeriod("");
    setAgeRangeFrom("");
    setAgeRangeTo("");
    setCaskSize("");
    setActive(true);
    setCaskType("");
    setNoOfCask("");
    setAgeRangeFromError("");
    setAgeRangeToError("");
    setPurposeError("");
    setBudgetError("");
    setDistilleryError("");
    setCaskSizeError("");
    setError("");
  };
  useEffect(() => {
    fetchCaskType();
  }, []);

  return (
    <>
      <section className="caskRequestGA">
        <Tabs
          defaultActiveKey="home"
          id=""
          bsPrefix="caskRqstGAHeader"
          className="mb-3"
          onSelect={(e) => setDefault(e)}
        >
          <Tab
            eventKey="home"
            title="INDIVIDUAL"
            tabClassName={
              activeClassIndividaul == true
                ? "activeClassCastRqst active"
                : "individualTab active"
            }
          >
            <div className="requestBody">
              <h6 className="bodyH">
                Please fill out the <b>Individual</b> Cask Request Form below or
                click <b>Surprise Me</b>.
              </h6>
              <div className={budgetError !==""?"error" :"noError"}>{budgetError}</div>
              <div className={purposeError !==""?"error" :"noError"}>{purposeError}</div>
              <div className={ageRangeFromError !==""?"error" :"noError"}>{ageRangeFromError}</div>
              <div className={ageRangeToError !==""?"error" :"noError"}>{ageRangeToError}</div>
              <div className={distilleryError !==""?"error" :"noError"}>{distilleryError}</div>
              <div className={caskSizeError !==""?"error" :"noError"}>{caskSizeError}</div>
              <div className={noOfCasksError !==""?"error" :"noError"}>{noOfCasksError}</div>
              <div className={error !==""?"error" :"noError"}>{error}</div>

              <div className="bodyContainerGA">
                <div className="caskRqstRow">
                  <h6>Budget :</h6>
                  <input
                    type="text"
                    id="total-shares"
                    placeholder=''
                    name="budget"
                    value={budgetInt}
                    onChange={(e) => validateBudget(e)}
                  />
                </div>
                <div className="caskRqstRow">
                  <h6>Purpose :</h6>
                  <input
                    type="text"
                    id="total-shares"
                    name="purpose"
                    value={purpose}
                    placeholder=''
                    onChange={(e) => validateField(e)}
                  />
                </div>
                <div className="caskRqstRow">
                  <h6>Age Range :</h6>
                  <input
                    className=""
                    type="text"
                    id="total-shares"
                    placeholder='"From"'
                    value={ageRangeFrom}
                    name="ageRangeFrom"
                    size="4"
                    onChange={(e) => validateField(e)}
                  />
                  <input
                    className=""
                    type="text"
                    id="total-shares"
                    placeholder='"To"'
                    value={ageRangeTo}
                    name="ageRangeTo"
                    size="4"
                    onChange={(e) => validateField(e)}
                  />
                </div>
                <div className="caskRqstRow">
                  <h6>Hold Period :</h6>

                  <select
                    name="holdPeriod"
                    id="type"
                    className="ageInput dropdown"
                    onChange={(e) => setHoldPeriod(e.target.value)}
                  >
                    <option value="Short Term">Short Term</option>
                    <option value="Medium Term">Medium Term</option>
                    <option value="Long Term">Long Term</option>
                    <option value="Mixed">Mixed</option>
                   
                  </select>
                </div>
                <div className="caskRqstRow">
                  <h6>Distillery :</h6>
                  <input
                    type="text"
                    id="share-price"
                    name="distillery"
                    value={distillery}
                    placeholder='"Distillery Name"'
                    onChange={(e) => validateField(e)}
                  />
                </div>
                <div className="caskRqstRow">
                  <h6>Cask Type :</h6>

                  <select
                    name="caskType"
                    id="type"
                    className="ageInput"
                    onChange={(e) => setCaskType(e.target.value)}
                  >
                    <option value="">Select Cask Type</option>{" "}
                    {caskTypeResponse &&
                      caskTypeResponse.map((caskTypeResponse) => (
                        <option
                          key={caskTypeResponse.cask_type_id}
                          value={caskTypeResponse.name}
                        >
                          {caskTypeResponse.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="caskRqstRow">
                  <h6>Cask Size :</h6>
                  <input
                    type="text"
                    id="total-shares"
                    placeholder=''
                    name="caskSize"
                    value={caskSize}
                    onChange={(e) => validateField(e)}
                  />
                </div>
                <div className="caskRequestFooter">
                  <button
                    className="caskGreenBtn"
                    onClick={handleSaveIndividual}
                  >
                    SUBMIT
                  </button>
                  <button
                    className="caskRedBtn"
                    onClick={handleSurpriseIndividual}
                  >
                    Surprise Me
                  </button>
                </div>
              </div>
              <h6 className="footerH">
                Once received, your <b>Private Marketplace</b> will then be
                populated with a bespoke portfolio of casks on completion of our
                search.
              </h6>
            </div>
          </Tab>

          <Tab
            eventKey="portfolio"
            title="PORTFOLIO"
            tabClassName={
              activeClassPortfolio == true
                ? "activeClassCastRqst active"
                : "individualTab active"
            }
          >
            <div className="requestBody">
              <h6 className="bodyH">
                Please fill out the <b>Portfolio</b> Cask Request Form below or
                click <b>Surprise Me</b>.
              </h6>
              <div className={budgetError !==""?"error" :"noError"}>{budgetError}</div>
              <div className={purposeError !==""?"error" :"noError"}>{purposeError}</div>
              <div className={ageRangeFromError !==""?"error" :"noError"}>{ageRangeFromError}</div>
              <div className={ageRangeToError !==""?"error" :"noError"}>{ageRangeToError}</div>
              <div className={distilleryError !==""?"error" :"noError"}>{distilleryError}</div>
              <div className={caskSizeError !==""?"error" :"noError"}>{caskSizeError}</div>
              <div className={noOfCasksError !==""?"error" :"noError"}>{noOfCasksError}</div>
              <div className={error !==""?"error" :"noError"}>{error}</div>
              <form className="bodyContainerGA">
                <div className="caskRqstRow">
                  <h6>Budget :</h6>
                  <input
                    type="text"
                    id="total-shares"
                    placeholder=''
                    name="budget"
                    value={budget}
                    onChange={(e) => validateBudget(e)}
                  />
                </div>

                <div className="caskRqstRow">
                  <h6>Purpose :</h6>
                  <input
                    type="text"
                    id="total-shares"
                    name="purpose"
                    value={purpose}
                    placeholder=''
                    onChange={(e) => validateField(e)}
                  />
                </div>
                <div className="caskRqstRow">
                  <h6>Age Range :</h6>
                  <input
                    className=""
                    type="text"
                    id="total-shares"
                    placeholder='"From"'
                    value={ageRangeFrom}
                    name="ageRangeFrom"
                    size="4"
                    onChange={(e) => validateField(e)}
                  />
                  <input
                    className=""
                    type="text"
                    id="total-shares"
                    placeholder='"To"'
                    value={ageRangeTo}
                    name="ageRangeTo"
                    size="4"
                    onChange={(e) => validateField(e)}
                  />
                </div>
                <div className="caskRqstRow">
                  <h6>Hold Period :</h6>

                  <select
                    name="holdPeriodP"
                    id="type"
                    className="ageInput"
                    onChange={(e) => setHoldPeriod(e.target.value)}
                  >
                    <option value="Short Term">Short Term</option>
                    <option value="Medium Term">Medium Term</option>
                    <option value="Long Term">Long Term</option>
                    <option value="Mixed">Mixed</option>
                  </select>
                </div>
                <div className="caskRqstRow">
                  <h6>Distillery :</h6>
                  <input
                    type="text"
                    id="share-price"
                    name="distillery"
                    value={distillery}
                    placeholder='"Distillery Name"'
                    onChange={(e) => validateField(e)}
                  />
                </div>
                <div className="caskRqstRow">
                  <h6>Cask Type :</h6>

                  <select
                    name="caskType"
                    id="type"
                    className="ageInput"
                    onChange={(e) => setCaskType(e.target.value)}
                  >
                    <option value="">Select Cask Type</option>{" "}
                    {caskTypeResponse &&
                      caskTypeResponse.map((caskTypeResponse) => (
                        <option
                          key={caskTypeResponse.cask_type_id}
                          value={caskTypeResponse.name}
                        >
                          {caskTypeResponse.name}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="caskRqstRow">
                  <h6>No.of Casks:</h6>
                  <input
                    type="text"
                    id="total-shares"
                    placeholder=''
                    name="noOfCasks"
                    value={noOfCasks}
                    onChange={(e) => validateField(e)}
                  />
                </div>
                <div className="caskRequestFooter">
                  <button
                    className="caskGreenBtn"
                    onClick={handleSavePortfolio}
                  >
                    SUBMIT
                  </button>
                  <button
                    className="caskRedBtn"
                    onClick={handleSurprisePortfolio}
                  >
                    Surprise Me
                  </button>
                </div>
              </form>
              <h6 className="footerH">
                Once received, your <b>Private Marketplace</b> will then be
                populated with a bespoke portfolio of casks on completion of our
                search.
              </h6>
            </div>
          </Tab>
        </Tabs>
        <div className="castRequestImg">
          <img src={walletImage} alt="" />
        </div>
      </section>
    </>
  );
};
export default IACaskRequest;
