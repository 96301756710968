import React, { useState } from "react";
import "./index.scss";
import { useLocation } from "react-router-dom";
import BuyCask from "./BuyCask";
import ProfileCard from "../../../components/Shared/ProfileCard/ProfileCard";
import leftIcon from "../../../assets/images/burgerIconLeft.svg";
import RightIcon from "../../../assets/images/burgerIconRight.svg";
import { useSelector, useDispatch } from "react-redux";
import { show, hide, sidebarStatus } from "../../../store/sidebar";

const GroupMemberBuyCask = () => {
  const location = useLocation();
  const { data } = location.state;
  const openSidebar = useSelector(sidebarStatus);
  const dispatch = useDispatch();
  return (
    <>
      <main
        id="mainDashboard"
        className={openSidebar ? "main" : "mainContainer"}
      >
        <div className="page-header">
          <div className="pagetitle">
            <div className="sideBarIconMain">
              {openSidebar == true && (
                <button onClick={() => dispatch(hide())} className="leftButton">
                  <img src={leftIcon} />
                </button>
              )}
              {openSidebar == false && (
                <button
                  onClick={() => dispatch(show())}
                  className="rightButton"
                >
                  <img src={RightIcon} />
                </button>
              )}
            </div>
            <h1>Buy Cask</h1>
          </div>
          <ProfileCard />
        </div>

        <section className="section dashboard">
          <BuyCask caskData={data} />
        </section>
      </main>
    </>
  );
};
export default GroupMemberBuyCask;
