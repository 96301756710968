import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ConfirmationPromptModal(props) {
  const show = props.show;
  const close = props.close;
  const confirmFunc = props.confirmFunc;
  
  return (
      <Modal show={show} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm ..</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you Sure, You want to continue?</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={confirmFunc}>
            Confirm
          </Button>
          <Button variant="danger" onClick={close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
  );
}

export default ConfirmationPromptModal;
