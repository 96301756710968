import React, { useState, useRef, useEffect } from "react";
import "./index.scss";
import { useNavigate } from "react-router-dom";
import { getUserData } from "../../../utils/helperFunctions";
import getEndpoints from "../../../../src/config/endpoints";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { setUserData } from "../../../utils/helperFunctions";
import ProfilePic from "../../../assets/images/profile_pic.png";
const ProfileCard = () => {
  const profileDatas = getUserData();
  const [profileData, setProfileData] = useState([]);
  const [profileFirstName, setProfileFirstName] = useState(
    profileDatas.first_name
  );
  const [profileLastName, setProfileLastName] = useState(
    profileDatas.last_name
  );
  const [displayEditProfile, setDisplayEditProfile] = useState(false);
  const [displayStatus, setDisplayStatus] = useState(false);
  const [displayStatusMessage, setDisplayStatusMessage] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isAEdited, setIsEdited] = useState(false);

  let navigate = useNavigate();
  const fileInputRef = useRef(null);
  const ProfileLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    navigate("/");
  };
  const validateProfileFirstName = (input) => {
    var regExp = /^[A-Za-z]+$/;
    if (input !== "") {
      if (input.match(regExp)) {
        setProfileFirstName(input);
      }
    } else {
      setProfileFirstName(null);
    }
  };
  const validateProfileLastName = (input) => {
    var regExp = /^[A-Za-z]+$/;
    if (input !== "") {
      if (input.match(regExp)) {
        setProfileLastName(input);
      }
    } else {
      setProfileLastName(null);
    }
  };
  const openProfile = () => {
    setSelectedImage(null);
    setDisplayEditProfile(true);
  };
  const closeProfile = () => {
    setDisplayEditProfile(false);
  };
  const handleProfileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    setProfilePic(event.target.files[0]);
    if (event.target.files[0]) {
      const imageUrl = URL.createObjectURL(event.target.files[0]);
      setSelectedImage(imageUrl);
    }
  };
  const triggerEditProfile = async () => {
    let formData = new FormData();
    formData.append("first_name", profileFirstName);
    formData.append("last_name", profileLastName);
    formData.append("user_id", profileData.user_id);
    formData.append("input_file", profilePic);

    try {
      const endpoint = getEndpoints().editProfile;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      if (response.data.success == true) {
        setUserData(response.data.userData);
        setDisplayEditProfile(false);
        setDisplayStatusMessage(true);
        setDisplayStatus(true);
        setIsEdited(true);
      } else {
        setDisplayStatus(true);
        setDisplayStatusMessage(false);
        setIsEdited(false);
      }
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  useEffect(() => {
    setProfileData(getUserData());
    console.log("user", getUserData());
    setIsEdited(false);
  }, [isAEdited]);
  useEffect(() => {
    setProfileData(getUserData());
  }, []);
  return (
    <>
      <Modal
        show={displayStatus}
        onHide={() => setDisplayStatus(!displayStatus)}
      >
        <Modal.Body>
          <div className="ProfileUpdateMessageMain">
            {displayStatusMessage == true && (
              <div>Profile Updated Successfully</div>
            )}
            {displayStatusMessage == false && <div>Profile Updated Failed</div>}
            <div>
              <Button
                variant="primary"
                onClick={() => setDisplayStatus(!displayStatus)}
              >
                Close
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <div className="profileCard">
        <img
          src={
            profileData.profile_pic_url === ""
              ? ProfilePic
              : profileData.profile_pic_url
          }
          height={70}
          width={70}
          className="profileImage"
        />

        <div className="profileInfo">
          <div className="profileName">
            {(profileData.first_name + " " + profileData.last_name).length > 13
              ? `${(
                  profileData.first_name +
                  " " +
                  profileData.last_name
                ).substring(0, 13)}...`
              : profileData.first_name + " " + profileData.last_name}
          </div>
          <div className="profileEmail">
            {profileData?.email?.length > 20
              ? `${profileData.email.substring(0, 20)}...`
              : profileData.email}
          </div>
          <div className="profileRole">{profileData.user_role}</div>
          <div className="profileLogOut" onClick={ProfileLogout}>
            Logout
          </div>
        </div>
        <div className="ThreeDotsRow" onClick={openProfile}>
          <div className="ThreeDot"></div>
          <div className="ThreeDot"></div>
          <div className="ThreeDot"></div>
        </div>
        <Modal show={displayEditProfile} className="editProfileMoadl">
          <Modal.Header>
            <Modal.Title
              id="contained-modal-title-vcenter"
              bsclass="profileTag"
            >
              Edit Profile
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="profilePic" onClick={handleProfileClick}>
              <img
                src={selectedImage || profileData.profile_pic_url || ProfilePic}
                alt="Edit Profile Pic"
                height={132}
                width={132}
                className="editProfileImage"
              />
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
            </div>
            <div className="settingsInputs">
              <input
                type="text"
                placeholder="First Name"
                required
                value={profileFirstName}
                onChange={(e) => validateProfileFirstName(e.target.value)}
              />
              <input
                type="text"
                placeholder="Last Name"
                required
                value={profileLastName}
                onChange={(e) => validateProfileLastName(e.target.value)}
              />
              <input
                type="email"
                placeholder="Email"
                required
                value={profileData.email}
                disabled
              />
              <input
                type="text"
                placeholder="Email"
                required
                value={profileData.user_role}
                disabled
              />
            </div>
          </Modal.Body>
          <Modal.Footer bsPrefix="settingsFooterMain">
            <div className="settingsFooter">
              <button className="greenBtn" onClick={triggerEditProfile}>
                SAVE
              </button>
              <button className="redBtn" onClick={closeProfile}>
                CANCEL
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
};

export default ProfileCard;
