import React, { useEffect, useState } from "react";
import getEndpoints from "../../../../src/config/endpoints";
import axios from "axios";
import { getUserData } from "../../../utils/helperFunctions";
import { Typeahead } from "react-bootstrap-typeahead";
import { useSelector, useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import vector from "./images/Vector.svg";
import PanelRightImage from "../../Broker/BrokerAdminDashboard/resources/uploadNew.png";
import "./index.scss";
import { Link, useNavigate } from "react-router-dom";
import UploadIcon from "./images/uploadIcon.svg";
import SuccessPrompt from "../../../components/Shared/SuccessPrompt/SuccessPrompt";
const UserPortfolio = () => {
  const profileData = getUserData();
  const [currentSelection, setCurrentSelection] = useState("");
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState({});
  const [userType, setUserType] = useState([]);
  const [typeEmailList, setTypeEmailList] = useState([]);
  const [selected, setSelected] = useState(null);
  const hiddenFileInput1 = React.useRef(null);
  const hiddenFileInput2 = React.useRef(null);
  const [memberId, setMemberId] = useState(null);
  const [userTypeId, setUserTypeId] = useState(null);
  const [headers, setHeaders] = useState(
    "Distillery - AYS - Casktype - LOA - ABV - OLA_RLA - Price"
  );
  // Success Prompt
  const [openSuccess, setOpenSuccess] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

   const handleClose = () => {
     setOpenSuccess(false);
   };

  let navigate = useNavigate();
  const typeEmailHandler = async (event) => {
    const user_type_id = event.target.value;
    setUserTypeId(user_type_id);
    setSelected(null);
    if (event.target.value === "3") {
      setHeaders(" Distillery - AYS - Casktype - LOA - ABV - OLA_RLA - Price");
    } else if (event.target.value === "4") {
      setHeaders(
        " Distillery - AYS - Casktype - LOA - ABV - OLA_RLA - Price - Shares - share_price"
      );
    }
    

    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    formData.append("user_type_id", user_type_id);
    try {
      const endpoint = getEndpoints().users.all_type_user_emails;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      setTypeEmailList(response.data.userData);
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  const fileUploadIndividual = async (event) => {
    const fileUploaded = event.target.files[0];
    let formData = new FormData();
    formData.append("file", fileUploaded);
    formData.append("user_id", profileData.user_id);

    formData.append("member_id", selected[0].user_id);
    formData.append("user_type_id", userTypeId);
    try {
      const endpoint = getEndpoints().superAdmin.userPortfolio.upload;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      if (response.data.success == true) {
        setSuccess(true)
        setOpenSuccess(true);
        setMessage(response.data.message);
        setModalData({ success: true, modalShow: true });
      } else {
         setSuccess(false);
         setOpenSuccess(true);
         setMessage(response.data.message);
        setModalData({ success: false, modalShow: true });
      }
      return response;
    } catch (e) {
      setModalData({ success: false, modalShow: true });
      return e.response || e;
    }
  };
  const triggerUploadIndividual = () => {
    if (selected[0]) {
      hiddenFileInput1.current.click();
      setCurrentSelection("Individual");
    }
  };
  const fileUploadPortfolio = async (event) => {
    const fileUploaded = event.target.files[0];
    let formData = new FormData();
    formData.append("files", fileUploaded);
    formData.append("user_id", profileData.user_id);
    formData.append("cask_name", "portfolio_cask");
    formData.append("id", selected[0].user_id);
    try {
      const endpoint = getEndpoints().cask.upload;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      if (response.data.success == "True") {
        setModalData({ success: true, modalShow: true });
      } else {
        setModalData({ success: false, modalShow: true });
      }
      return response;
    } catch (e) {
      setModalData({ success: false, modalShow: true });
      return e.response || e;
    }
  };
  const triggerUploadPortfolio = () => {
    navigate("/broker/user-portfolio/view");
  };
  const userTypeHandle = async () => {
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    formData.append("user_type_id", profileData.user_type_id);
    try {
      const endpoint = getEndpoints().users.user_types;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      setUserType(response.data);
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  const updateUserSelection = (val) => {
    console.log()
    val && setSelected(val);
    val[0] && setMemberId(val[0].user_id);
  };
  useEffect(() => {
    userTypeHandle();
  }, []);
  return (
    <>
      <SuccessPrompt
        success={success}
        showStatus={openSuccess}
        message={message}
        handleClose={handleClose}
      />
      <div className="PrivateUploadSectionA">
        <div>
          <div className="input-group">
            <div className="PrivateuserType">
              <select
                className="form-select privateDrpdwn"
                id="sel1"
                name="sellist1"
                onChange={typeEmailHandler}
              >
                <option value="" selected disabled hidden>
                  User Type
                </option>
                {userType &&
                  userType.map((type) => {
                    if (type.user_type_id === 1 || type.user_type_id === 2) {
                      return;
                    }
                    return (
                      <option value={type.user_type_id} key={type.user_type_id}>
                        {type.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className="PrivateuserEmail">
              <Typeahead
                id="basic-typeahead-single"
                labelKey="email"
                onChange={(val) => {
                  updateUserSelection(val);
                }}
                options={typeEmailList}
                placeholder="User's Email"
                selected={selected}
              />
            </div>
          </div>
        </div>
        <div className="PrivateUploadSectionLeft">
          <div className="PrivateUploadSectionTop">
            <form>
              <input
                type="file"
                accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                style={{ display: "none" }}
                ref={hiddenFileInput1}
                onChange={fileUploadIndividual}
              />
              <Button
                className="PrivateUploadButton"
                onClick={triggerUploadIndividual}
              >
                <div className="PrivateuploadIcon">
                  <img src={UploadIcon} />
                </div>
                <div className="uploadIconText">Upload Portfolio</div>
              </Button>
            </form>
            <div className="PrivatesectionCaptionOne">User Portfolio</div>
            <div className="UploadUserPortfolio">
              Click if you uploading a <b>portfolio </b> of a specific user.
              Please make sure the excel file you upload has the following
              headers:
            </div>
            <div className="PrivatesectionCaptionThree">{headers}</div>
          </div>
          <div className="borderBottom">
            -----------------------------------------------------------
          </div>
          <div className="PrivateUploadSectionBottom">
            <form>
              <input
                type="file"
                accept=".csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                style={{ display: "none" }}
                ref={hiddenFileInput2}
                onChange={fileUploadPortfolio}
              />
              {console.log(selected)}
              {selected !== null && selected !== [] ? (
                <Link
                  to="/broker/user-portfolio/view"
                  state={{
                    memberId: memberId,
                  }}
                >
                  <Button className="PrivateUploadButton">
                    <div className="PrivateuploadIcon">
                      <img src={vector} />
                    </div>
                    <div className="uploadIconText"> View Portfolio</div>
                  </Button>
                </Link>
              ) : (
                <Link
                  to="/broker/user-portfolio/view"
                  onClick={(event) => event.preventDefault()}
                >
                  <Button className="PrivateUploadButton" disabled>
                    <div className="PrivateuploadIcon">
                      <img src={vector} />
                    </div>
                    <div className="uploadIconText"> View Portfolio</div>
                  </Button>
                </Link>
              )}
              {/* <Link
                to="/broker/user-portfolio/view"
                state={{
                  memberId: memberId,
                }}
              >
                <Button className="PrivateUploadButton" disabled>
                  <div className="PrivateuploadIcon">
                    <img src={vector} />
                  </div>
                  <div className="uploadIconText"> View Portfolio</div>
                </Button>
              </Link> */}
            </form>
            <div className="PrivatesectionCaptionOne">View User Portfolio</div>
            <div className="viewUserPortfolio">
              Click if you want to see <b>Portfolio</b> of this specific user.
            </div>
          </div>
        </div>
        <div className="PrivateUploadSectionRight">
          <img src={PanelRightImage} />
        </div>
      </div>
    </>
  );
};
export default UserPortfolio;
