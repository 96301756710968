import React, { useState, useEffect } from "react";
import authenticationManagementAPIs from "../../../services/authentication.service";
import {
  PriceComponent,
  getToken,
  getUserData,
} from "../../../utils/helperFunctions";
import "./index.scss";
const BorkerPortfolio = () => {
  const [responseDatas, setResponseDatas] = useState([]);
  const userData = getUserData();
  const token = getToken();
  const fetchData = async () => {
    try {
      const res = await authenticationManagementAPIs.portfolioBroker(
        token,
        JSON.stringify(userData.user_id)
      );
      setResponseDatas(res.data);
    } catch (e) {
      return e.response || e;
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div>
        <table>
          <thead className="table-head">
            <tr>
              <th scope="col">DISTILLERY</th>

              <th scope="col">AYS</th>
              <th scope="col">CASK TYPE</th>
              <th scope="col">LOA</th>
              <th scope="col">ABV</th>
              <th scope="col">OLA/RLA</th>
              <th scope="col">PRICE</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {responseDatas &&
              responseDatas.map((responseData) => (
                <tr key={responseData.cask_id} className="portfolioBrkrDataRow">
                  <td data-label="Name">{responseData.distillery}</td>
                  {/* <div className="portfolioLineBroker"></div> */}
                  <td data-label="Date">{responseData.ays}</td>
                  <td data-label="Type">{responseData.casktype}</td>
                  <td data-label="LOA">{responseData.loa}</td>
                  <td data-label="ABV">{responseData.abv} %</td>
                  <td data-label="OLA/RLA">{responseData.ola_rla}</td>
                  <td data-label="Prize">
                    £{PriceComponent(responseData.price)}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default BorkerPortfolio;
