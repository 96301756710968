import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

const RouteAuthIA = (props) => {
  const [isLoggedIn, setLogin] = useState(true);
  const location = useLocation();
  useEffect(() => {
    console.log(props.userType);
  });
  return (
    <>
      {props.userType == 5 ? (
        props.children
      ) : (
        <Navigate to="/" state={{ from: location }} replace />
      )}
    </>
  );
};

export default RouteAuthIA;
