import { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";

const RouteAuth = (props) => {
  const [isLoggedIn, setLogin] = useState(true);
  const location = useLocation();
    useEffect(() => {
    console.log(props.userType);
})
  return (
    <>
      {props.userType == 2 ? (
        props.children
      ) : (
        <Navigate to="/" state={{ from: location }} replace />
      )}
    </>
  );
};

export default RouteAuth;
