import React, { useEffect, useState } from "react";
import getEndpoints from "../../../../src/config/endpoints";
import axios from "axios";
import Button from "react-bootstrap/Button";
import userMapping from "./images/user-mapping.svg";
import SuccessPrompt from "../../../components/Shared/SuccessPrompt/SuccessPrompt";
const UserMapping = () => {
  const profileData = JSON.parse(localStorage.getItem("userData"));
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [userType, setUserType] = useState([]);
  const [parents, setParents] = useState([]);
  const [parentId, setParentId] = useState("");
  const [userTypeId, setUserTypeId] = useState([]);

  const [openSuccess, setOpenSuccess] = useState(false);
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const userTypeHandle = async () => {
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    formData.append("user_type_id", profileData.user_type_id);
    try {
      const endpoint = getEndpoints().users.user_types;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      setUserType(response.data);
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  const parentlHandler = async (event) => {
    setUserTypeId(event.target.value);
    const user_type_id = event.target.value;
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    formData.append("user_type_id", user_type_id);
    try {
      const endpoint = getEndpoints().users.parents;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });

      setParents(response.data.userData);
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  const validateField = (e) => {
    if (e.target.name === "email") {
      setEmail(e.target.value);
    } else if (e.target.name === "password") {
      setPassword(e.target.value);
    }
  };
  const setDefault = () => {
    setParentId("");
    setEmail("");
    setPassword("");
    setUserTypeId("");
    setUserType([]);
    setParents([]);
    userTypeHandle();
    setSuccess(false);
    setMessage("");
    userTypeHandle();
  };
  const handleSubmit = async () => {
    if (email != "" && password != "" && userTypeId != "" && parentId != "") {
      let formData = new FormData();
      formData.append("user_id", profileData.user_id);
      formData.append("email", email);
      formData.append("password", password);
      formData.append("user_type_id", userTypeId);
      formData.append("parent_id", parentId);
      try {
        const endpoint = getEndpoints().users.create_user;
        const response = await axios.post(endpoint, formData, {
          headers: { token: profileData.token },
        });
        console.log(response.data.success, "response.success");
        if (response.data.success === true) {
          setSuccess(true);
          setOpenSuccess(true);
          setMessage(response.data.message);
        } else {
          setSuccess(false);
          setOpenSuccess(true);
          setMessage(response.data.message);
        }
        return response;
      } catch (e) {
        return e.response || e;
      }
    } else {
      console.log("error");
    }
  };
  const handleClose = () => {
    setOpenSuccess(false);
    setDefault();
  };

  const areAllInputsFilled = () => {
    return email != "" && password != "" && userTypeId != "" && parentId != "";
  };
  useEffect(() => {
    userTypeHandle();
  }, []);

  return (
    <>
      <SuccessPrompt
        success={success}
        showStatus={openSuccess}
        message={message}
        handleClose={handleClose}
      />
      <div className="bodyContainerGA">
        <div className="error">{}</div>
        <div className="createRow">
          <h6>Email :</h6>
          <input
            type="text"
            placeholder="Email"
            name="email"
            value={email}
            onChange={(e) => validateField(e)}
          />
        </div>

        <div className="createRow">
          <h6>Password :</h6>
          <input
            type="password"
            name="password"
            value={password}
            placeholder="********"
            onChange={(e) => validateField(e)}
          />
        </div>
        <div className="createRow">
          <h6>User Type :</h6>
          <select
            className="form-select userTypeSelect"
            id="sel1"
            name="sellist1"
            onChange={parentlHandler}
          >
            <option value="" selected disabled hidden>
              User Type
            </option>
            {userType &&
              userType.map((type) => {
                if (type.user_type_id == 1) {
                  return;
                }
                return (
                  <option value={type.user_type_id} key={type.user_type_id}>
                    {type.name}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="createRow">
          <h6>Parent :</h6>
          <select
            className="form-select userTypeSelect"
            id="sel1"
            name="sellist1"
            onChange={(event) => setParentId(event.target.value)}
          >
            <option value="" selected disabled hidden>
              Parent
            </option>
            {parents &&
              parents.map((parent) => {
                return (
                  <option value={parent.user_id} key={parent.user_id}>
                    {parent.email}
                  </option>
                );
              })}
          </select>
        </div>

        <div className="mbrshipVotingCreateFooter">
          <Button
            className={
              areAllInputsFilled() ? "caskGreenBtn" : "caskGreenBtnInActive"
            }
            onClick={handleSubmit}
            disabled={!areAllInputsFilled()}
          >
            SUBMIT
          </Button>
          <Button className="caskRedBtn" onClick={setDefault}>
            CLEAR
          </Button>
        </div>
      </div>
      <div className="userMapImgSec">
        <img className="userMapImg" src={userMapping} />
      </div>
    </>
  );
};
export default UserMapping;
