import React, { useEffect, useState } from "react";
import authenticationManagementAPIs from "../../../services/authentication.service";
import Button from "react-bootstrap/Button";
import { PriceComponent, getToken, getUserData } from "../../../utils/helperFunctions";
import { useNavigate, Link } from "react-router-dom";

const PrivateMarketPlace = () => {
  const [marketPublicData, setMarketPublicData] = useState([]);
  let navigate = useNavigate();
  const userData = getUserData();
  const token = getToken();
  const triggerBuy = () => {
    // navigate("/groupmember/marketplace/buycask/public");
  };

  //   Public marketplace progress
  const loadPublicMarketData = async () => {
    let formData = new FormData();
    formData.append("user_id", JSON.stringify(userData.user_id));
    const response = await authenticationManagementAPIs.GMPrivateMarketplace(
      token,
      formData
    );

    setMarketPublicData(response.data);
  };
  //Like update api
  const triggerLike = async (isLike, caskId) => {
    let formData = new FormData();
    formData.append("user_id", JSON.stringify(userData.user_id));
    formData.append("isLike", isLike);
    formData.append("caskId", caskId);
    const response =
      await authenticationManagementAPIs.GMPublicMarketplaceLikeUpdate(
        token,
        formData
      );
    loadPublicMarketData();
  };
  useEffect(() => {
    loadPublicMarketData();
  }, []);
  return (
    <>
      <div>
        <table>
          <thead className="table-head">
            <tr>
              <th scope="col">DISTILLERY</th>
              <th scope="col">AYS</th>
              <th scope="col">CASK TYPE</th>
              <th scope="col">LOA</th>
              <th scope="col">ABV</th>
              <th scope="col">OLA/RLA</th>
              <th scope="col">SHARE PRICE</th>
              {/* <th scope="col">LIKE</th> */}
              <th scope="col">ACTION</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {marketPublicData &&
              marketPublicData.map((marketData, index) => {
                return (
                  <tr key={index}>
                    <td data-label="DISTILLERY">{marketData.distillery}</td>
                    <td data-label="AYS">{marketData.ays}</td>
                    <td data-label="CASKTYPE">{marketData.casktype}</td>
                    <td data-label="LOA">{marketData.loa}</td>
                    <td data-label="ABV">{marketData.abv} %</td>
                    <td data-label="OLA/RLA">{marketData.ola_rla}</td>
                    <td data-label="SHARE PRICE">£{PriceComponent(marketData.share_price)}</td>
                    <td data-label="ACTION">
                      <Link
                        to="/groupmember/marketplace/buycask/private"
                        state={{ data: marketData }}
                      >
                        <Button value={index} className="buyBtnGM">
                          BUY
                        </Button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default PrivateMarketPlace;
