export const setToken = (token) => {
  localStorage.setItem("token", JSON.stringify(token));
};

export const getToken = () => {
  return JSON.parse(localStorage.getItem("token"));
};
export const setUserData = (userData) => {
  localStorage.setItem("userData", JSON.stringify(userData));
};
export const getUserData = () => {
  return JSON.parse(localStorage.getItem("userData"));
};
export const PriceComponent = (price) => {
  const priceAsDecimal = parseFloat(price);
  const formattedPrice = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(priceAsDecimal);

  return <span>{formattedPrice}</span>;
}
