import React, { useState, useEffect } from "react";
import authenticationManagementAPIs from "../../../services/authentication.service";
import { PriceComponent, getToken, getUserData } from "../../../utils/helperFunctions";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import "./index.scss";
import { useNavigate, Link } from "react-router-dom";
import getEndpoints from "../../../config/endpoints";
import axios from "axios";
import DeletePrompt from "../../../components/Shared/DeletePrompt/deletePromptModal";
const IndividualAdminPortfolio = () => {
  let [uploadType, setUploadType] = useState("");
  let [triggerUpdate, setTriggerUpdate] = useState(0);
  let [triggerCask, setTriggerCask] = useState(null);
  let [deleteTrigger, setDeleteTrigger] = useState(false);
  let [uploadCaskId, setUploadCaskId] = useState(null);
  const hiddenInputTitleUpload = React.useRef(null);
  const hiddenInputDeedUpload = React.useRef(null);
  let navigate = useNavigate();
  const [responseDatas, setResponseDatas] = useState([]);
  const userData = getUserData();
  const token = getToken();

  const UploadCertificateHandler = async (event) => {
    const fileUploaded = event.target.files[0];
    let formData = new FormData();
    formData.append("input_file", fileUploaded);
    formData.append("user_id", userData.user_id);
    formData.append("cask_id", uploadCaskId);
    formData.append("name", uploadType);
    try {
      const endpoint = getEndpoints().groupadmin.upload.certificate;
      const response = await axios.post(endpoint, formData, {
        headers: { token: userData.token },
      });
      setTriggerUpdate(triggerUpdate + 1);
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  const triggerUploadTitle = (val) => {
    hiddenInputTitleUpload.current.click();
    setUploadCaskId(val);
    setUploadType("title");
  };
  const triggerUploadDeed = (val) => {
    setUploadCaskId(val);
    hiddenInputDeedUpload.current.click();
    setUploadType("deed");
  };

  const triggerDocDelete = async () => {
    let formData = new FormData();
    formData.append("user_id", userData.user_id);
    formData.append("cask_id", triggerCask);
    formData.append("key", uploadType);
    try {
      const endpoint = getEndpoints().groupadmin.delete.certificate;
      const response = await axios.post(endpoint, formData, {
        headers: { token: userData.token },
      });
      setDeleteTrigger(false);
      setTriggerUpdate(triggerUpdate + 1);
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  const triggerDeleteModal = (caskId, type) => {
    setTriggerCask(caskId);
    setUploadType(type);
    setDeleteTrigger(true);
  };
  const closeDeleteModal = () => {
    setDeleteTrigger(false);
  };

  const headerToggle = () => {};
  const fetchData = async () => {
    const response = await authenticationManagementAPIs.portfolioGroupAdmin(
      token,
      JSON.stringify(userData.user_id)
    );
    setResponseDatas(response.data);
  };
  const fileNameExtractor = (url) => {
    const temp_url = url.split("?")[0];
    var filename = temp_url.split("/").pop();
    return filename.length > 10 ? `${filename.substring(0, 10)}...` : filename;
  };
  useEffect(() => {
    fetchData();
  }, [triggerUpdate]);

  return (
    <>
      <DeletePrompt
        show={deleteTrigger}
        confirmFunc={triggerDocDelete}
        closeFunc={closeDeleteModal}
      />
      <div>
        <table>
          <thead className="table-head">
            <tr>
              <th scope="col">DISTILLERY</th>
              <th scope="col">AYS</th>
              <th scope="col">CASK TYPE</th>
              <th scope="col">LOA</th>
              <th scope="col">ABV</th>
              <th scope="col">OLA/RLA</th>
              <th scope="col">PRICE</th>
            </tr>
          </thead>
          <tbody className="table-body">
            <Accordion defaultActiveKey="0" flush>
              {responseDatas &&
                responseDatas.map((responseData, index) => {
                  return (
                    <div key={index} className="portfolioTable">
                      <Accordion.Item eventKey={index} bsPrefix="accordianItem">
                        <Accordion.Header onClick={headerToggle}>
                          <tr className="accordianRow">
                            <td data-label="DISTILLERY">
                              {responseData.distillery}
                            </td>
                            {/* <div className="portfolioLine"></div> */}
                            <td data-label="AYS" className="GdportfolioAYS">
                              {responseData.ays}
                            </td>
                            <td data-label="CASKTYPE">
                              {responseData.casktype}
                            </td>
                            <td data-label="LOA">{responseData.loa}</td>
                            <td data-label="ABV">{responseData.abv} %</td>
                            <td data-label="OLA/RLA">{responseData.ola_rla}</td>
                            <td data-label="PRICE">
                              £{PriceComponent(responseData.price)}
                            </td>
                          </tr>
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="accordianBody">
                            <div className="accordianBodyShare">
                              <div className="accordianBodyShareQuantity">
                                {responseData.available_shares}/
                                {responseData.total_shares}
                              </div>
                              <div className="accordianBodyShareTag">
                                Shares Available
                              </div>
                            </div>
                            <div className="accordianBodyTitle">
                              <input
                                type="file"
                                accept="application/pdf"
                                style={{ display: "none" }}
                                ref={hiddenInputTitleUpload}
                                onChange={UploadCertificateHandler}
                              />
                              <Button
                                className="accordianTitleCert"
                                onClick={() =>
                                  triggerUploadTitle(responseData.cask_id)
                                }
                                disabled={
                                  responseData.title_s3url !== null
                                    ? true
                                    : false
                                }
                              >
                                UPLOAD
                              </Button>
                              <div className="accordianFileName">
                                {responseData.title_s3url !== null &&
                                  fileNameExtractor(responseData.title_s3url)}
                              </div>
                              <div className="accordianActionRow">
                                <a
                                  href={responseData.title_s3url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Button
                                    variant="link"
                                    className="accordianActionView"
                                    disabled={
                                      responseData.title_s3url == null
                                        ? true
                                        : false
                                    }
                                  >
                                    View
                                  </Button>
                                </a>
                                <Button
                                  variant="link"
                                  className="accordianActionDelete"
                                  disabled={
                                    responseData.title_s3url == null
                                      ? true
                                      : false
                                  }
                                  onClick={() =>
                                    triggerDeleteModal(
                                      responseData.cask_id,
                                      "title"
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>

                            <div className="accordianBodyDeed">
                              <input
                                type="file"
                                accept="application/pdf"
                                style={{ display: "none" }}
                                ref={hiddenInputDeedUpload}
                                onChange={UploadCertificateHandler}
                              />

                              <Button
                                variant="light"
                                className="accordianDeedUpload"
                                onClick={() =>
                                  triggerUploadDeed(responseData.cask_id)
                                }
                                disabled={
                                  responseData.deed_s3url !== null
                                    ? true
                                    : false
                                }
                              >
                                UPLOAD
                              </Button>
                              <div className="accordianFileNameDeed">
                                {responseData.deed_s3url !== null &&
                                  fileNameExtractor(responseData.deed_s3url)}
                              </div>
                              <div className="accordianActionRowDeed">
                                <a
                                  href={responseData.deed_s3url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <Button
                                    variant="link"
                                    className="accordianActionViewDeed"
                                    disabled={
                                      responseData.deed_s3url == null
                                        ? true
                                        : false
                                    }
                                  >
                                    View
                                  </Button>
                                </a>

                                <Button
                                  variant="link"
                                  className="accordianActionDeleteDeed"
                                  disabled={
                                    responseData.deed_s3url == null
                                      ? true
                                      : false
                                  }
                                  onClick={() =>
                                    triggerDeleteModal(
                                      responseData.cask_id,
                                      "deed"
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                              </div>
                            </div>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </div>
                  );
                })}
            </Accordion>
          </tbody>
        </table>
      </div>
    </>
  );
};
export default IndividualAdminPortfolio;
