import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import "./index.scss";
const MemberViewModal = (props) => {
  const [modalBoxShow, setModalBoxShow] = useState(props.setDisplay);
  const memberData = props.memberData;
  const onClose = () => {
    setModalBoxShow(false);
  };
  useEffect(() => {
    setModalBoxShow(props.setDisplay);
  }, [props]);
  return (
    <>
      <Modal show={modalBoxShow} className="ModalOuterDiv">
        <Modal.Body>
          <div className="MemberModalBody">
            <div className="memberModalBodyMain">
              <div className="memberModalHeadingView">Member Details</div>
            </div>
            <div className="memberInputFields">
              <div className="memberInputRow">
                <div className="memberInputRowLabel">Name:</div>
                <div className="memberInputRow">{memberData.first_name}</div>
              </div>
              <div className="memberInputRow">
                <div className="memberInputRowLabel">Email ID:</div>
                <div>{memberData.email}</div>
              </div>
              <div className="memberInputRow">
                <div className="memberInputRowLabel">Contact:</div>
                <div>{memberData.phone_no}</div>
              </div>
              <div className="memberInputRow">
                <div className="memberInputRowLabel">Address:</div>
                <div>{memberData.address}</div>
              </div>
              <div className="memberInputRow">
                <div className="memberInputRowLabel">Shares:</div>
                <div>{memberData.shares}</div>
              </div>
            </div>
            <div className="btnRowError">
              <button className="btnRedCancelViewMember" onClick={props.hideTrigger}>
                CLOSE
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default MemberViewModal;
