import React, { useState, useEffect } from "react";
import authenticationManagementAPIs from "../../../services/authentication.service";
import { PriceComponent, getToken, getUserData } from "../../../utils/helperFunctions";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";
import { useNavigate, Link } from "react-router-dom";

import "./index.scss";
const GroupMemberPortfolio = () => {
  let [triggerUpdate, setTriggerUpdate] = useState(0);
  const [responseDatas, setResponseDatas] = useState([]);
  const userData = getUserData();
  const token = getToken();

  const headerToggle = () => {};
  const fetchData = async () => {
    const response = await authenticationManagementAPIs.portfolioGroupMember(
      token,
      JSON.stringify(userData.user_id)
    );
    setResponseDatas(response.data);
  };
  const fileNameExtractor = (url) => {
    const temp_url = url.split("?")[0];
    var filename = temp_url.split("/").pop();
    return filename.length > 10 ? `${filename.substring(0, 10)}...` : filename;
  };
  useEffect(() => {
    fetchData();
  }, [triggerUpdate]);

  return (
    <>
      <div className="groupMemberPortfolio">
        <table>
          <thead className="table-head">
            <tr>
              <th scope="col">DISTILLERY</th>
              <th scope="col">AYS</th>
              <th scope="col">CASK TYPE</th>
              <th scope="col">LOA</th>
              <th scope="col">ABV</th>
              <th scope="col">OLA/RLA</th>
              <th scope="col">PRICE</th>
            </tr>
          </thead>
          <tbody className="table-body">
            <Accordion defaultActiveKey="0" flush>
              {responseDatas &&
                responseDatas.map((responseData, index) => (
                  <div key={index} className="portfolioTable">
                    <Accordion.Item
                      eventKey={index}
                      key={index}
                      bsPrefix="accordianItem"
                    >
                      <Accordion.Header onClick={headerToggle}>
                        <tr className="accordianRow">
                          <td data-label="DISTILLERY">
                            {responseData.distillery}
                          </td>
                          {/* <div className="portfolioLine"></div> */}
                          <td data-label="AYS">{responseData.ays}</td>
                          <td data-label="Type">{responseData.casktype}</td>
                          <td data-label="LOA">{responseData.loa}</td>
                          <td data-label="ABV">{responseData.abv} %</td>
                          <td data-label="OLA/RLA">{responseData.ola_rla}</td>
                          <td data-label="Price">
                            £{PriceComponent(responseData.price)}
                          </td>
                        </tr>
                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="accordianBody">
                          <div className="accordianBodyShare">
                            <div className="accordianBodyShareQuantity">
                              {responseData.shares}/{responseData.total_shares}
                            </div>
                            <div className="accordianBodyShareTag">
                              Shares Available
                            </div>
                          </div>
                          <div className="accordianBodyTitle">
                            <div className="accordianFileName">
                              {responseData.title_s3url !== null &&
                                fileNameExtractor(responseData.title_s3url)}
                            </div>
                            <div className="accordianActionRow">
                              <a
                                href={responseData.title_s3url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Button
                                  variant="link"
                                  className="accordianActionView"
                                  disabled={
                                    responseData.title_s3url == null
                                      ? true
                                      : false
                                  }
                                >
                                  View
                                </Button>
                              </a>
                            </div>
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                ))}
            </Accordion>
          </tbody>
        </table>
      </div>
    </>
  );
};
export default GroupMemberPortfolio;
