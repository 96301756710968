import React, { useState } from "react";
import LOGIN_PAGE_IMG from "../../../assets/images/login-new.png";
import LOGO from "../../../assets/images/login-logo.svg";
import authenticationManagementAPIs from "../../../services/authentication.service";
import { setToken, setUserData } from "../../../utils/helperFunctions";
import { useNavigate } from "react-router-dom";
import "./index.scss";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState("");
  let navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    const responseData = await authenticationManagementAPIs.login(
      email,
      password
    );

    if (responseData.data.success == true) {
      setToken(responseData.data.token);
      setUserData(responseData.data);

      if (
        responseData.data.updated_at !== null &&
        responseData.data.updated_at !==""
      ) {
        if (responseData.data.user_type_id == 2) {
          navigate("/broker/");
        } else if (responseData.data.user_type_id == 3) {
          navigate("/groupadmin/");
        } else if (responseData.data.user_type_id == 4) {
          navigate("/groupmember/");
        } else if (responseData.data.user_type_id == 5) {
          navigate("/individualadmin/");
        } else {
          setErrors("User not found");
          return responseData.data;
        }
      } else {
        //First login
        if (responseData.data.user_type_id == 2) {
          navigate("/broker/broker-create");
        } else if (responseData.data.user_type_id == 3) {
          navigate("/groupadmin/groupadmin-create");
        } else if (responseData.data.user_type_id == 4) {
          navigate("/groupmember/groupmember-create");
        } else if (responseData.data.user_type_id == 5) {
          navigate("/individualadmin/individualadmin-create");
        } else {
          setErrors("User not found");
        }
      }
    } else if (responseData.data.error == "user not found") {
      setErrors("User not found");
    } else {
      setErrors("Please enter valid credentials");
    }
  };

  return (
    <>
      <main className="brokerLogin">
        <div className="loginImg">
          <img className="img" src={LOGIN_PAGE_IMG} alt="" />
        </div>

        <div className="container">
          <div className="logoB">
            <img className="logoImg" src={LOGO} alt="" />
          </div>
          <div className="containerTitleB">
            <h4 className="hSign">SIGN IN</h4>
            <p className="pSign">Sign In to get started.</p>
          </div>

          <form className="loginFormB" onSubmit={(e) => handleSubmit(e)}>
            <div className="inputsB">
              <div className="loginInputB">
                <input
                  type="email"
                  name="email"
                  className="form-control txt-field"
                  placeholder="E-mail Address"
                  id="yourUsername"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <div className="invalid-error"></div>
              </div>
              <div className="loginInputB">
                <input
                  type="password"
                  name="password"
                  className="form-control txtField"
                  placeholder="Password"
                  id="yourPassword"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
                <div className="invalidError">{errors}</div>
              </div>
              <div>
                <span className="forg">
                  <a href="/forgot-password">Forgot Password?</a>
                </span>
              </div>
            </div>
            <div className="signBtnB">
              <button className="btnB">SIGN IN</button>
            </div>
          </form>
        </div>
      </main>
    </>
  );
};

export default Login;
