import React from "react";
import home from "../Menu/images/home.png";
import box from "../Menu/images/box.svg";
import save from "../Menu/images/save.svg";
import message from "../Menu/images/message.svg";
import transaction from "../Menu/images/transaction.svg";
import wallet from "../Menu/images/wallet.svg";
import voting from "../Menu/images/voting.svg";
export const SidebarData = [
  {
    title: "Portfolio",
    path: "/groupmember/",
    icon: <img src={home} className="nav-img" alt="home" />,
  },
  {
    title: "Marketplace",
    path: "#",
    icon: <img src={box} className="nav-img" alt="home" />,
    iconClosed: <i className="bi bi-chevron-down upload-icon-brkr"></i>,
    iconOpened: <i className="bi bi-chevron-up upload-icon-brkr"></i>,

    subNav: [
      {
        title: "Public",
        path: "/groupmember/marketplace/public",
        icon: <img src={save} alt="public" />,
      },
      {
        title: "Private",
        path: "/groupmember/marketplace/private",
        icon: <img src={save} alt="private" />,
      },
    ],
  },
  {
    title: "Voting",
    path: "/groupmember/voting",
    icon: <img src={voting} className="nav-img" alt="voting" />,
  },
  {
    title: "Transaction",
    path: "/groupmember/transactions",
    icon: <img src={transaction} className="nav-img" alt="transaction" />,
  },
];
