import React, { useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Auth from "../components/Auth";
import GroupAdminDashboard from "../pages/GroupAdmin/GroupAdminDashboard";
import GroupAdminWallet from "../pages/GroupAdmin/GroupAdminWallet";
import GroupAdminWalletWithdraw from "../pages/GroupAdmin/GroupAdminWalletWithdraw";
import GroupAdminWalletDeposit from "../pages/GroupAdmin/GroupAdminDeposit";
import GATransactionsMain from "../pages/GroupAdmin/GroupAdminTransaction";
import GroupAdminMemberList from "../pages/GroupAdmin/GroupAdminMemberList";
import IndividualAdminMarketPublic from "../pages/IndividualAdmin/IndividualAdminMarketPublic";
import IndividualAdminMarketPrivate from "../pages/IndividualAdmin/IndividualAdminMarketPrivate";
import GroupAdminBuyCask from "../pages/IndividualAdmin/IndividualAdminBuyCask";
import GroupAdminBuyCaskMultiple from "../pages/IndividualAdmin/IndividualAdminBuyCaskMultiple";

import GroupAdminCaskRequest from "../pages/GroupAdmin/GroupAdminCaskRequest";
import GAMembershipVotingCreate from "../pages/GroupAdmin/GroupAdminMembershipVoting/CreatePoll/index";
import GAMembershipVotingView from "../pages/GroupAdmin/GroupAdminMembershipVoting/ViewPoll";
import IndividualSideBar from "../components/IndividualSideBar";
import IndividualAdminDashboard from "../pages/IndividualAdmin/IndividualAdminDashboard";
import IndividualAdminWallet from "../pages/IndividualAdmin/IndividualAdminWallet";
import IndividualAdminWalletWithdraw from "../pages/IndividualAdmin/IndividualAdminWalletWithdraw";
import IndividualAdminCaskRequest from "../pages/IndividualAdmin/IndividualAdminCaskRequest";
import IndividualAdminWalletDeposit from "../pages/IndividualAdmin/IndividualAdminDeposit";

const IndividualAdminRoutes = () => {
  const location = useLocation();
  const [openSidebar, setOpenSidebar] = useState(true);

  return (
    <>
      <IndividualSideBar openSidebar={openSidebar} />

      <Routes>
        <Route
          path="/"
          element={
            <Auth>
              <IndividualAdminDashboard />
            </Auth>
          }
        />
        <Route
          path="/wallet"
          element={
            <Auth>
              <IndividualAdminWallet />
            </Auth>
          }
        />
        <Route
          path="/wallet/withdraw"
          element={
            <Auth>
              <IndividualAdminWalletWithdraw />
            </Auth>
          }
        />
        <Route
          path="/wallet/deposit"
          element={
            <Auth>
              <IndividualAdminWalletDeposit />
            </Auth>
          }
        />
        <Route
          path="/transactions"
          element={
            <Auth>
              <GATransactionsMain />
            </Auth>
          }
        />

        <Route
          path="/marketplace/public"
          element={
            <Auth>
              <IndividualAdminMarketPublic />
            </Auth>
          }
        />
        <Route
          path="/marketplace/private"
          element={
            <Auth>
              <IndividualAdminMarketPrivate />
            </Auth>
          }
        />
        <Route
          path="/marketplace/buycask_multiple/private"
          element={
            location.state !== null ? (
              <Auth>
                <GroupAdminBuyCaskMultiple />
              </Auth>
            ) : (
              <Navigate replace to={"/individualadmin/"} />
            )
          }
        />
        <Route
          path="/marketplace/buycask/:type"
          element={
            location.state !== null ? (
              <Auth>
                <GroupAdminBuyCask />
              </Auth>
            ) : (
              <Navigate replace to={"/individualadmin/"} />
            )
          }
        />

        <Route
          path="/cask-request"
          element={
            <Auth>
              <IndividualAdminCaskRequest />
            </Auth>
          }
        />
        <Route
          path="/create-poll"
          element={
            <Auth>
              <GAMembershipVotingCreate />
            </Auth>
          }
        />
        <Route
          path="/view-poll"
          element={
            <Auth>
              <GAMembershipVotingView />
            </Auth>
          }
        />
        <Route exact path="*" element={<h1>Page Not Found</h1>} />
      </Routes>
    </>
  );
};

export default IndividualAdminRoutes;
