import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
  name: "sidebar",
  initialState: {
    value: true,
  },
  reducers: {
    show: (state) => {
      state.value = true;
    },
    hide: (state) => {
      state.value = false;
    },
  },
});

export const { show, hide } = counterSlice.actions;

export const sidebarStatus = (state) => state.sidebar.value;

export default counterSlice.reducer;
