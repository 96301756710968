import React from "react";
import Modal from "react-bootstrap/Modal";
import "./index.scss";
import votingOption1 from "../../../../assets/images/option1_icon.svg";
import votingOption2 from "../../../../assets/images/option2_icon.svg";
import votingOption3 from "../../../../assets/images/option3_icon.svg";
import votingOption4 from "../../../../assets/images/option4_icon.svg";
import wineCask from "../../../../assets/images/option_icon.svg";
function PollViewModal(props) {
  const showModal = props.showModal;
  const close = props.close;
  const votingData = props.votingData;
  return (
    <>
      <Modal show={showModal} onHide={close}>
        <Modal.Body bsPrefix="ViewPollModal">
          {votingData && (
            <>
              <div className="ViewPollModalHead">
                <div className=" title">
                  <div className="col-2"></div>
                  <div className="col-8">
                    <h4 className="headText">
                      {votingData && votingData.question}
                    </h4>
                  </div>
                  <div className="col-2">
                    <button
                      className="btn-close btn-close-white"
                      onClick={close}
                    ></button>
                  </div>
                </div>
              </div>
              <div className="viewPollBody">
                <div className="inputs">
                  <div className="input-row">
                    <div className="row d-flex align-items-center justify-content-center">
                      <div className="row voting">
                        <div className="votingpanel">
                          <div className="row barchart">
                            <div className="row contents">
                              <div className="col-3 iconsfull">
                                <div className="icons">
                                  <img src={votingOption1} alt="Grand Cru" />
                                  {votingData && votingData.option1}
                                </div>
                                <div className="icons">
                                  <img src={votingOption2} alt="Grand Cru" />
                                  {votingData && votingData.option2}
                                </div>
                                <div className="icons">
                                  <img src={votingOption3} alt="Grand Cru" />
                                  {votingData && votingData.option3}
                                </div>
                                <div className="icons">
                                  <img src={votingOption4} alt="Grand Cru" />
                                  {votingData && votingData.option4}
                                </div>
                              </div>
                              <div className="col-9 chartfull">
                                <div className="chart">
                                  <div
                                    className="bar1"
                                    style={{
                                      height:
                                        (votingData.option1_count /
                                          (votingData.option1_count +
                                            votingData.option2_count +
                                            votingData.option3_count +
                                            votingData.option4_count)) *
                                        100,
                                    }}
                                  ></div>
                                  <div
                                    className="bar2"
                                    style={{
                                      height:
                                        (votingData.option2_count /
                                          (votingData.option1_count +
                                            votingData.option2_count +
                                            votingData.option3_count +
                                            votingData.option4_count)) *
                                        100,
                                    }}
                                  ></div>
                                  <div
                                    className="bar3"
                                    style={{
                                      height:
                                        (votingData.option3_count /
                                          (votingData.option1_count +
                                            votingData.option2_count +
                                            votingData.option3_count +
                                            votingData.option4_count)) *
                                        100,
                                    }}
                                  ></div>
                                  <div
                                    className="bar4"
                                    style={{
                                      height:
                                        (votingData.option4_count /
                                          (votingData.option1_count +
                                            votingData.option2_count +
                                            votingData.option3_count +
                                            votingData.option4_count)) *
                                        100,
                                    }}
                                  ></div>
                                </div>
                                <div className="voteMain">
                                  <div className="date">
                                    {votingData &&
                                      votingData.option1_count + " Votes"}
                                  </div>
                                  <div className="date">
                                    {votingData &&
                                      votingData.option2_count + " Votes"}
                                  </div>
                                  <div className="date">
                                    {votingData &&
                                      votingData.option3_count + " Votes"}
                                  </div>
                                  <div className="date">
                                    {votingData &&
                                      votingData.option4_count + " Votes"}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row buttonsfull">
                            <div className="upload">
                              <button
                                className={
                                  votingData &&
                                  votingData.winner === "option1"
                                    ? "winnerBtn"
                                    : "optionBtn"
                                }
                              >
                                <div className="round">
                                  <img
                                    className="wineImage"
                                    src={wineCask}
                                    alt="upload"
                                  />
                                </div>
                                {votingData && votingData.option1}
                              </button>
                            </div>
                            <div className="upload">
                              <button
                                className={
                                  votingData && votingData.winner === "option2"
                                    ? "winnerBtn"
                                    : "optionBtn"
                                }
                              >
                                <div className="round1">
                                  <img
                                    className="wineImage"
                                    src={wineCask}
                                    alt="upload"
                                  />
                                </div>
                                {votingData && votingData.option2}
                              </button>
                            </div>
                          </div>
                          <div className="row buttonsfull">
                            <div className="upload">
                              <button
                                className={
                                  votingData && votingData.winner === "option3"
                                    ? "winnerBtn"
                                    : "optionBtn"
                                }
                              >
                                <div className="round2">
                                  <img
                                    className="wineImage"
                                    src={wineCask}
                                    alt="upload"
                                  />
                                </div>
                                {votingData && votingData.option3}
                              </button>
                            </div>
                            <div className="upload">
                              <button
                                className={
                                  votingData && votingData.winner === "option4"
                                    ? "winnerBtn"
                                    : "optionBtn"
                                }
                              >
                                <div className="round3">
                                  <img
                                    className="wineImage"
                                    src={wineCask}
                                    alt="upload"
                                  />
                                </div>
                                {votingData && votingData.option4}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default PollViewModal;
