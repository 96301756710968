import React, { useEffect, useState } from "react";
import authenticationManagementAPIs from "../../../services/authentication.service";
import Button from "react-bootstrap/Button";
import { PriceComponent, getToken, getUserData } from "../../../utils/helperFunctions";
import { useNavigate, Link } from "react-router-dom";
const MarketPublic = () => {
  const [marketPublicData, setMarketPublicData] = useState([]);
  let navigate = useNavigate();
  const userData = getUserData();
  const token = getToken();
  const triggerBuy = () => {
    navigate("/groupadmin/marketplace/buycask/public");
  };

  const loadPublicMarketDataHandler = async () => {
    const response = await authenticationManagementAPIs.groupAdminMarketPublic(
      token,
      JSON.stringify(userData.user_id)
    );
    setMarketPublicData(response.data);
  };
  useEffect(() => {
    loadPublicMarketDataHandler();
  }, []);
  return (
    <>
      <div>
        <table>
          <thead className="table-head">
            <tr>
              <th scope="col">DISTILLERY</th>
              <th scope="col">AYS</th>
              <th scope="col">CASK TYPE</th>
              <th scope="col">LOA</th>
              <th scope="col">ABV</th>
              <th scope="col">OLA/RLA</th>
              <th scope="col">PRICE</th>
              <th scope="col">LIKE</th>
              <th scope="col">ACTION</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {marketPublicData &&
              marketPublicData.map((marketData, index) => {
                return (
                  <tr key={index}>
                    <td data-label="DISTILLERY">{marketData.distillery}</td>
                    <td data-label="AYS">{marketData.ays}</td>
                    <td data-label="CASKTYPE">{marketData.casktype}</td>
                    <td data-label="LOA">{marketData.loa}</td>
                    <td data-label="ABV">{marketData.abv} %</td>
                    <td data-label="OLA/RLA">{marketData.ola_rla}</td>
                    <td data-label="PRICE">
                    £{PriceComponent(marketData.price)}
                    </td>
                    <td data-label="LIKE">
                      <div className="likeDataDiv">
                        {marketData.likes_count}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="8"
                          fill="currentColor"
                          className="bi bi-heart-fill likeIcon"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M8 1.314C12.438-3.248 23.534 4.735 8 15-7.534 4.736 3.562-3.248 8 1.314z"
                          />
                        </svg>
                      </div>
                    </td>
                    <td data-label="ACTION">
                      <Link
                        to="/groupadmin/marketplace/buycask/public"
                        state={{ data: marketData }}
                      >
                        <Button value={index} className="greenButtonMain">
                          BUY
                        </Button>
                      </Link>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default MarketPublic;
