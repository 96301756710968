import React, { useEffect, useState } from "react";
import getEndpoints from "../../../../../src/config/endpoints";
import axios from "axios";
import Button from "react-bootstrap/Button";
import { useLocation } from "react-router-dom";
import { PriceComponent } from "../../../../utils/helperFunctions";
const UserPortfolioView = (props) => {
  const [caskList, setCaskList] = useState([]);
  const profileData = JSON.parse(localStorage.getItem("userData"));
  const location = useLocation();
  const { memberId } = location.state;
  console.log(location.state);
  const userPortfolioHandler = async () => {
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    formData.append("member_id", memberId);
    try {
      const endpoint = getEndpoints().superAdmin.userPortfolio.view;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });

      setCaskList(response.data.userData);
      return response;
    } catch (e) {
      return e.response || e;
    }
  };

  useEffect(() => {
    userPortfolioHandler();
  }, []);
  return (
    <div>
      <table>
        <thead className="table-head">
          <tr>
            <th scope="col">DISTILLERY</th>
            <th scope="col"> AYS</th>
            <th scope="col"> CASK TYPE</th>
            <th scope="col"> LOA</th>
            <th scope="col"> ABV</th>
            <th scope="col"> OLA/RLA</th>
            <th scope="col"> PRICE</th>
          </tr>
        </thead>
        <tbody className="table-body">
          {caskList &&
            caskList.map((cask, index) => {
              return (
                <tr key={index}>
                  <td data-label="DISTILLERY">{cask.distillery}</td>
                  <td data-label="AYS">{cask.ays}</td>
                  <td data-label=" CASK TYPE">{cask.casktype}</td>
                  <td data-label="LOA">{cask.loa}</td>
                  <td data-label="ABV">{cask.abv} %</td>
                  <td data-label="OLA_RLA">{cask.ola_rla}</td>
                  <td data-label="OLA_RLA">£{PriceComponent(cask.price)}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};
export default UserPortfolioView;
