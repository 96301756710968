import React, { useState } from "react";
import ProfileCard from "../../../components/Shared/ProfileCard/ProfileCard";
import "./index.scss";
// import leftIcon from "../../../assets/images/burgerIconLeft.svg";
import leftIcon from "../../../assets/images/burgerIconLeft.svg";
import RightIcon from "../../../assets/images/burgerIconRight.svg";
import { useSelector, useDispatch } from "react-redux";
import { show, hide, sidebarStatus } from "../../../store/sidebar";
import UserPortfolio from "./userPortfolio";

const UserPortfolioSuperAdmin = () => {
  const openSidebar = useSelector(sidebarStatus);
  const dispatch = useDispatch();


  return (
    <div className="mainContainerOuter">
      <main
        id="mainDashboard"
        className={openSidebar ? "main" : "mainContainer"}
      >
        <div className="page-header">
          <div className="pagetitle">
            <div className="sideBarIconMain">
              {openSidebar == true && (
                <button onClick={() => dispatch(hide())} className="leftButton">
                  <img src={leftIcon} />
                </button>
              )}
              {openSidebar == false && (
                <button
                  onClick={() => dispatch(show())}
                  className="rightButton"
                >
                  <img src={RightIcon} />
                </button>
              )}
            </div>
            <h1>User Portfolio</h1>
          </div>
          <ProfileCard />
        </div>

        <section className="section dashboard portfolio">
          <UserPortfolio />
        </section>
      </main>
    </div>
  );
};
export default UserPortfolioSuperAdmin;
