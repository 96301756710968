import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { PriceComponent, getUserData } from "../../../utils/helperFunctions";
import getEndpoints from "../../../../src/config/endpoints";
import InvoiceBroker from "../BrokerInvoice/index";
const Transactions = () => {
  const [transactionList, setTransactionList] = useState([]);
  const profileData = getUserData();

  const [showInvoice, setShowInvoice] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);
  const [date, setDate] = useState("");
  const [walletAction, setWalletAction] = useState("");
  const [transAmount, setTransAmount] = useState("");
  const loadCaskRequestHandler = async () => {
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    try {
      const endpoint = getEndpoints().transactions.transaction;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      setTransactionList(response.data);
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  // const triggerdDetailedView = (caskDetails,walletTransaction) => {
  //   setShowInvoice(true);
  //   setInvoiceData(caskDetails);
  //   setTotalData(walletTransaction);
  // };
  const triggerdDetailedViewHide = () => {
    setShowInvoice(false);
  };
  const triggerdDetailedView = async (transaction) => {
    setShowInvoice(true);
    setTransAmount(transaction.tran_amount);
    setWalletAction(transaction.wallet_action);
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    formData.append("transactionId", transaction.transaction_id);
    try {
      const endpoint = getEndpoints().transactions.invoice;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      // setTransactionList(response.data);
      setInvoiceData(response.data);
      setDate(transaction.trans_date);

      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  useEffect(() => {
    loadCaskRequestHandler();
  }, []);
  return (
    <>
      <InvoiceBroker
        setDisplay={showInvoice}
        invoiceData={invoiceData}
        date={date}
        onHide={triggerdDetailedViewHide}
        walletAction={walletAction}
        transAmount={transAmount}
      />
      <div>
        <table>
          <thead className="table-head">
            <tr>
              <th scope="col">NO.</th>
              <th scope="col">ACTION</th>
              <th scope="col">STATUS</th>
              <th scope="col">DATE</th>
              <th scope="col">AMOUNT</th>
              <th scope="col">INVOICE</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {transactionList &&
              transactionList.map((transaction, index) => {
                return (
                  <tr key={index}>
                    <td data-label="NO">{index + 1}</td>
                    <td data-label="ACTION">{transaction.wallet_action}</td>
                    <td data-label="STATUS">{transaction.trans_status}</td>
                    <td data-label="DATE">{transaction.trans_date}</td>
                    <td data-label="AMOUNT">
                      £
                      {transaction.tran_amount &&
                        PriceComponent(transaction.tran_amount)}
                    </td>
                    <td data-label="INVOICE">
                      <Button
                        className="greenButtonMain"
                        // value={index}
                        onClick={() => triggerdDetailedView(transaction)}
                      >
                        VIEW
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default Transactions;
