import React from "react";
import home from "../Menu/images/home.png";
import box from "../Menu/images/box.svg";
import save from "../Menu/images/save.svg";
import message from "../Menu/images/message.svg";
import transaction from "../Menu/images/transaction.svg";
import wallet from "../Menu/images/wallet.svg";
import payoutRequest from "../Menu/images/payout-request.svg";
import userMapping from "../Menu/images/user-mapping.svg";
import bag from "../Menu/images/bag.svg";

export const SidebarData = [
  {
    title: "Portfolio",
    path: "/broker/",
    icon: <img src={home} className="nav-img" alt="home" />,
  },
  {
    title: "Upload List",
    path: "#",
    icon: <img src={box} className="nav-img" alt="home" />,
    iconClosed: <i className="bi bi-chevron-down upload-icon-brkr"></i>,
    iconOpened: <i className="bi bi-chevron-up upload-icon-brkr"></i>,

    subNav: [
      {
        title: "Stock",
        path: "/broker/upload/stock",
        icon: <img src={save} alt="stock" />,
      },
      {
        title: "Public",
        path: "/broker/upload/public",
        icon: <img src={save} alt="public" />,
      },
      {
        title: "Private",
        path: "/broker/upload_private",
        icon: <img src={save} alt="private" />,
      },
    ],
  },
  {
    title: "User Portfolio",
    path: "/broker/user-portfolio",
    icon: <img src={bag} className="nav-img" alt="request" />,
  },
  {
    title: "Request",
    path: "/broker/cask-request",
    icon: <img src={message} className="nav-img" alt="request" />,
  },
  {
    title: "Payout Request",
    path: "/broker/payout-request",
    icon: <img src={payoutRequest} className="nav-img" alt="payout request" />,
  },
  {
    title: "User Mapping",
    path: "/broker/user-mapping",
    icon: <img src={userMapping} className="nav-img" alt="payout request" />,
  },
  {
    title: "Wallet",
    path: "/broker/wallet",
    icon: <img src={wallet} className="nav-img" alt="wallet" />,
  },
  {
    title: "Transaction",
    path: "/broker/transactions",
    icon: <img src={transaction} className="nav-img" alt="transaction" />,
  },
];
