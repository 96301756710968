import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import successImage from "../../../../assets/images/Success.png";
import errorImage from "../../../../assets/images/error.png";
import "./index.scss";
const ModalBox = (props) => {
  const successStatus = props.success;
  const triggerUploadAgain = props.dataFunction;
  const triggerModalClose = props.closeModal;
  const pageType = props.uploadType;
  const [modalBoxShow, setModalBoxShow] = useState(props.modalShow);
  const modalBoxShowHandler = () => {
    triggerModalClose();
    setModalBoxShow(false);
  };
  useEffect(() => {
    setModalBoxShow(props.modalShow);
  }, [props]);
  return (
    <>
      <Modal show={modalBoxShow} className="ModalOuterDivBroker">
        <Modal.Body>
          <div className="modalBody">
            {successStatus === true && (
              <>
                <div className="modalBodyContainer">
                  <img
                    src={successImage}
                    alt="image loading failed...."
                    className="modalBodySuccessImage"
                  />
                  <div className="modalBodyMain">
                    <div className="modalBodySuccessText">Success</div>
                    <div className="modalBodySuccessTagLine">
                      You have successfully submitted your list to the
                      <div className="PublicTag"> {pageType} Marketplace.</div>
                    </div>
                  </div>
                  <div className="btnRow">
                    <button
                      className="btnRowgreen"
                      onClick={modalBoxShowHandler}
                    >
                      CLOSE
                    </button>
                  </div>
                </div>
              </>
            )}

            {successStatus === false && (
              <>
                <div className="modalBodyContainer">
                  <img
                    src={errorImage}
                    alt="image loading failed...."
                    className="modalBodySuccessImage"
                  />
                  <div className="modalBodyMain">
                    <div className="modalBodySuccessTextError">
                      Upload Error
                    </div>
                    <div className="modalBodySuccessTagLineError">
                      Unfortunately your upload has not been submitted. Please
                      make sure you to follow the submission rules.
                    </div>
                  </div>
                  <div className="btnRowError">
                    <button
                      className="btnRedUpload"
                      onClick={triggerUploadAgain}
                    >
                      UPLOAD
                    </button>
                    <button
                      className="btnRedCancel"
                      onClick={modalBoxShowHandler}
                    >
                      CLOSE
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ModalBox;
