import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { getToken, getUserData } from "../../../../utils/helperFunctions";
import authenticationManagementAPIs from "../../../../services/authentication.service";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./index.scss";
import DeletePromptModal from "../../../../components/Shared/DeletePrompt/deletePromptModal";
import PollViewModal from "../PollViewModal/PollViewModal";
const GAMembershipVotingViewPoll = () => {
  const [currentList, setCurrentList] = useState([]);
  const [pastList, setPastList] = useState([]);
  const [deleteShow, setDeleteShow] = useState(false);
  const [triggerUpdate, setTriggerUpdate] = useState(0);
  const [deleteId, setDeleteId] = useState(null);
  const [activeClassCreate, setActiveClassCreate] = useState(true);
  const [activeClassPast, setActiveClassPast] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const userData = getUserData();
  const user_id = JSON.stringify(userData.user_id);
  const [votingData, setVotingData] = useState();

  const viewCurrent = async () => {
    let formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("status", "current");
    const token = getToken();
    const responseDataCurrent =
      await authenticationManagementAPIs.MembershipVotingView(token, formData);
    if (responseDataCurrent.data) {
      setCurrentList(responseDataCurrent.data);
    }
  };

  const modalTriggerHide = () => {
    setShowModal(false);
  };

  const triggerdDeleteHide = () => {
    setDeleteId(null);
    setDeleteShow(false);
  };

  const triggerdDelete = (val) => {
    setDeleteId(val);
    setDeleteShow(true);
  };

  const triggerdDeleteVote = async () => {
    let formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("membership_voting_id", deleteId);
    const token = getToken();
    const responseData =
      await authenticationManagementAPIs.MembershipVotingDelete(
        token,
        formData
      );
    if (responseData.data) {
      setTriggerUpdate(triggerUpdate + 1);
      triggerdDeleteHide();
    }
  };

  const viewPast = async () => {
    let formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("status", "past");
    const token = getToken();
    const responseData =
      await authenticationManagementAPIs.MembershipVotingView(token, formData);
    if (responseData.data) {
      setPastList(responseData.data);
    }
  };

  const setActive = (e) => {
    if (e == "home") {
      setActiveClassCreate(true);
      setActiveClassPast(false);
    } else {
      setActiveClassPast(true);
      setActiveClassCreate(false);
    }
  };
  const votingResultData = (votingData) => {
    setVotingData(votingData);
    setShowModal(true);
  };

  useEffect(() => {
    viewCurrent();
    viewPast();
  }, [triggerUpdate]);

  return (
    <>
      <DeletePromptModal
        show={deleteShow}
        confirmFunc={triggerdDeleteVote}
        closeFunc={triggerdDeleteHide}
      />
      <PollViewModal
        showModal={showModal}
        close={modalTriggerHide}
        votingData={votingData}
      />
      <section className="membershipVotingView">
        <Tabs
          defaultActiveKey="home"
          id=""
          bsPrefix="mmbrshipVotingCreateHeader"
          className="mb-3"
          onSelect={(e) => setActive(e)}
        >
          <Tab
            eventKey="home"
            title="CURRENT"
            tabClassName={
              activeClassCreate == true ? "activeClass active" : "createTab"
            }
          >
            <div className="mbrshipVotingCreateBody">
              <table>
                <thead className="table-head">
                  <tr>
                    <th scope="col" className="votingIdColumn">
                      NO.
                    </th>
                    <th scope="col" className="votingColumn">
                      TITLE
                    </th>
                    <th scope="col" className="votingColumn">
                      WINNER
                    </th>
                    <th scope="col" className="votingColumn">
                      DATE
                    </th>
                    <th scope="col" className="actionColumn">
                      ACTIONS
                    </th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {currentList &&
                    currentList.map((currentList, index) => {
                      return (
                        <tr key={index} className="mbrshipVotingCreateRow">
                          <td data-label="NO" className="votingIdColumn">
                            {index + 1}
                          </td>
                          <td data-label="TITLE" className="votingColumn">
                            {currentList.title}
                          </td>
                          <td data-label="WINNER" className="votingColumn">
                            {currentList.winner}
                          </td>
                          <td data-label="DATE" className="votingColumn">
                            {currentList.time_period}
                          </td>
      
                          <td data-label="ACTIONS" className="actionColumn">
                            <div className="actionButtonRow">
                              <Button
                                className="greenButtonMain"
                                onClick={() => votingResultData(currentList)}
                              >
                                VIEW
                              </Button>
                              <Button
                                className="redButtonMain"
                                value={currentList.membership_voting_id}
                                onClick={(e) => triggerdDelete(e.target.value)}
                              >
                                DELETE
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </Tab>

          <Tab
            eventKey="past"
            title="PAST"
            tabClassName={
              activeClassPast == true ? "activeClass active" : "createTab"
            }
          >
            <div className="mbrshipVotingCreateBody">
              <table>
                <thead className="table-head">
                  <tr>
                    <th scope="col" className="votingIdColumn">
                      NO.
                    </th>
                    <th scope="col" className="votingColumn">
                      TITLE
                    </th>
                    <th scope="col" className="votingColumn">
                      WINNER
                    </th>
                    <th scope="col" className="votingColumn">
                      DATE
                    </th>
                    <th scope="col" className="actionColumn">
                      ACTIONS
                    </th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {pastList &&
                    pastList.map((pastList, index) => {
                      return (
                        <tr key={index} className="mbrshipVotingCreateRow">
                          <td data-label="NO" className="votingIdColumn">
                            {index + 1}
                          </td>
                          <td data-label="TITLE" className="votingColumn">
                            {pastList.title}
                          </td>
                          <td data-label="WINNER" className="votingColumn">
                            {pastList.winner}
                          </td>
                          <td data-label="DATE" className="votingColumn">
                            {pastList.time_period}
                          </td>

                          <td data-label="ACTIONS" className="actionColumn">
                            <div className="actionButtonRow">
                              <Button
                                className="greenButtonMain"
                                onClick={() => votingResultData(pastList)}
                              >
                                VIEW
                              </Button>
                              <Button
                                className="redButtonMain"
                                value={pastList.membership_voting_id}
                                onClick={(e) => triggerdDelete(e.target.value)}
                              >
                                DELETE
                              </Button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </Tab>
        </Tabs>
      </section>
    </>
  );
};
export default GAMembershipVotingViewPoll;
