import React, { useState, useEffect } from "react";
import "./index.scss";
import { useLocation } from "react-router-dom";
import { getUserData } from "../../../utils/helperFunctions";
import getEndpoints from "../../../../src/config/endpoints";
import axios from "axios";
import MemberListModal from "./MemberListModal";
import MemberViewModal from "./MemberViewModal";
import DeletePrompt from "../../../components/Shared/DeletePrompt/deletePromptModal";
import ConfirmationPromptModal from "../../../components/Shared/ConfirmationBox/confirmationPrompt";
const GroupAdminMemberInfo = (props) => {
  const [memberList, setMemberList] = useState([]);
  const [memberData, setMemberData] = useState([]);
  const [viewStatus, setViewStatus] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [deletingMember, setDeletingMember] = useState(null);
  const [deletePrompt, setDeletePrompt] = useState(false);
  const location = useLocation();
  const { caskid } = location.state;
  const profileData = getUserData();
  const [modalShow, setModalShow] = useState(false);
  const deleteTrigger = (val) => {
    setDeletePrompt(true);
    setDeletingMember(val);
  };
  const viewHideTrigger = () => {
    setViewStatus(false);
  };
  const hideDeleteTrigger = () => {
    setDeletePrompt(false);
    setDeletingMember(null);
  };
  const handleMemberDelete = async () => {
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    formData.append("cask_id", caskid);
    formData.append("member_id", deletingMember);
    try {
      const endpoint = getEndpoints().groupadmin.member.delete;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      hideDeleteTrigger();
      setTrigger(trigger + 1);
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  const handleMemberView = (memberSet) => {
    setViewStatus(true);
    setMemberData(memberSet);
  };
  const handleMemberList = async () => {
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    formData.append("cask_id", caskid);
    try {
      const endpoint = getEndpoints().groupadmin.memberlist;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      setMemberList(response.data);
      return response;
    } catch (e) {
      return e.response || e;
    }
  };

  useEffect(() => {
    handleMemberList();
  }, [trigger]);

  return (
    <>
      <MemberViewModal
        setDisplay={viewStatus}
        memberData={memberData}
        hideTrigger={viewHideTrigger}
      />
      <MemberListModal
        setDisplay={props.modalStatus}
        caskData={caskid}
        hideTriggerFunc={props.hideTrigger}
      />
      <DeletePrompt
        show={deletePrompt}
        confirmFunc={handleMemberDelete}
        closeFunc={hideDeleteTrigger}
      />
      <div className="memberListMain">
        <table>
          <thead className="table-head">
            <tr>
              <th scope="col">FIRST</th>
              <th scope="col">LAST</th>
              <th scope="col">EMAIL</th>
              <th scope="col">CONTACT</th>
              <th scope="col">SHARES</th>
              <th scope="col">ACTION</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {memberList &&
              memberList.map((member) => (
                <tr key={member.member_id}>
                  <td data-label="FIRST">{member.first_name}</td>
                  <td data-label="LAST">{member.last_name}</td>
                  <td data-label="EMAIL">{member.email}</td>
                  <td data-label="CONTACT">{member.phone_no}</td>
                  <td data-label="SHARES">{member.shares.toLocaleString()}</td>
                  <td data-label="ACTION">
                    <div className="buttonRowMemberList">
                      <button
                        onClick={() => {
                          handleMemberView(member);
                        }}
                        className="viewGreenbtn"
                      >
                        VIEW
                      </button>

                      <button
                        value={member.member_id}
                        onClick={(e) => {
                          deleteTrigger(e.target.value);
                        }}
                        className="deleteRedbtn"
                      >
                        DELETE
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default GroupAdminMemberInfo;
