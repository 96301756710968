import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Logo from "../../../assets/images/logo.png";
import { PriceComponent, getUserData } from "../../../utils/helperFunctions";
import "./index.scss";
const InvoiceGA = (props) => {
  const profileData = getUserData();
  let [showModal, setShowModal] = useState(props.setDisplay);
  const invoiceSet = props.invoiceData;
  const setHide = props.onHide;
  const totalData = props.totalData;
  const [subTotal, setSubTotal] = useState(0);
  const [transactionFee, setTransactionFee] = useState(0);
  const [platformFee, setPlatformFee] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [walletAction, setWalletAction] = useState(0);

  const amountData = () => {
    if (totalData) {
      setWalletAction(totalData.wallet_action);
    }
    let sumTotal = 0;
    if (invoiceSet !== null) {
      if (totalData.wallet_action === "Buy") {
        Object.keys(invoiceSet).map((index) => {
          if (Number(invoiceSet[index].price_buy) !== "") {
            sumTotal = sumTotal + Number(invoiceSet[index].price_buy);
          }
        });
        setSubTotal(sumTotal);
        setPlatformFee(sumTotal * (5 / 100)); //set 5% platform fee
        setTransactionFee(sumTotal * (5 / 100)); //set 5% transaction fee
        setTotalAmount(sumTotal * (5 / 100) + sumTotal * (5 / 100) + sumTotal);
      }
      if (totalData.wallet_action === "Sell") {
        Object.keys(invoiceSet).map((index) => {
          if (Number(invoiceSet[index].shares_price) !== "") {
            sumTotal = sumTotal + Number(invoiceSet[index].shares_price);
          }
        });
        setSubTotal(sumTotal);
        setPlatformFee(sumTotal * (5 / 100)); //set 5% platform fee
        setTransactionFee(sumTotal * (5 / 100)); //set 5% transaction fee
        setTotalAmount(sumTotal * (5 / 100) + sumTotal * (5 / 100) + sumTotal);
      }
      if (
        totalData.wallet_action === "Withdraw" &&
        totalData.wallet_action === "Deposit"
      ) {
      }
    }
  };

  useEffect(() => {
    setShowModal(props.setDisplay);
    amountData();
  }, [props]);

  return (
    <>
      <Modal show={showModal}>
        <Modal.Body bsPrefix="invoiceGA">
          <div className="invoiceMainGA">
            <div className="invoiceHeadGA">
              <div className="logoInvoiceGA">
                <img src={Logo} className="img-fluid" />
              </div>
              <div className="address">
                <h6>Bit Cask</h6>
                <p>160 Robinson Road, #14-04</p>
                <p>Singapore Business Federation Centre</p>
                <p>Singapore</p>
                <p>068914</p>
              </div>
            </div>
            <div className="invoiceBodyGA">
              <div className="invoice-details1">
                <h4>INVOICE</h4>
              </div>
              <div className="invoice-details">
                <div className="invoice-left">
                  <div className="invoice-content">
                    <h6>Paid By :</h6>
                    <p>
                      {profileData.first_name + " " + profileData.last_name}
                    </p>
                  </div>
                  <div className="invoice-content">
                    <h6>Address :</h6>
                    <p>10 Dover Drive Singapore 138683</p>
                  </div>
                </div>
                <div className="invoice-center">
                  <div className="invoice-content">
                    <h6>Invoice Number :</h6>
                    <p>BC-INV/2545-125/210</p>
                  </div>
                </div>
                <div className="invoice-right">
                  <div className="invoice-content1">
                    <h6>Date :</h6>
                    <p>{totalData && totalData.trans_date}</p>
                  </div>
                  <div className="invoice-content1">
                    <h6>Payment Method :</h6>
                    {totalData && <p>{totalData.wallet_action}</p>}
                  </div>
                </div>
              </div>
              <div className="invoice-bill">
                <div className="invoice-table-container">
                  <table className="invoice-table">
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Quantity</th>
                        <th>Price</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {walletAction !== "Withdraw" &&
                        walletAction !== "Deposit" &&
                        invoiceSet &&
                        Object.keys(invoiceSet).map((index) => {
                          return (
                            <tr>
                              <td>
                                {invoiceSet[index].distillery_buy &&
                                  invoiceSet[index].distillery_buy +
                                    ", " +
                                    invoiceSet[index].casktype_buy}
                                {invoiceSet[index].distillery_sell &&
                                  invoiceSet[index].distillery_sell +
                                    ", " +
                                    invoiceSet[index].casktype_sell}
                                <div className="description">
                                  {invoiceSet[index].distillery_buy &&
                                    "Entire Cask"}
                                </div>
                              </td>
                              <td>
                                {invoiceSet[index].distillery_sell &&
                                  invoiceSet[index].shares}
                              </td>
                              <td>
                                {invoiceSet[index].price_buy &&
                                  "£" +
                                    PriceComponent(
                                      invoiceSet[index].price_buy
                                    )}
                                {invoiceSet[index].distillery_sell &&
                                  "£" + PriceComponent(invoiceSet[index].shares_price)}
                                <div className="description1">
                                  {invoiceSet[index].casktype_sell}
                                </div>
                              </td>
                              <td>
                                {" "}
                                {invoiceSet[index].price_buy &&
                                  " £" +
                                    PriceComponent(invoiceSet[
                                      index
                                    ].price_buy)}
                                {invoiceSet[index].distillery_sell &&
                                  "£" + PriceComponent(invoiceSet[index].shares_price)}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <img src={Logo} alt="" className="invoice-logo" />
                </div>
                <div className="invoice-total">
                  <div>
                    <p>Subtotal</p>
                    <h6>£{PriceComponent(subTotal)}</h6>
                  </div>
                  <div>
                    <p>Platform Fee (5%)</p>
                    <h6>£{PriceComponent(platformFee)}</h6>
                  </div>
                  <div>
                    <p>Transaction Fee (5%)</p>
                    <h6>£{PriceComponent(transactionFee)}</h6>
                  </div>
                  <div>
                    <p>Discount</p>
                    <h6>£0.00</h6>
                  </div>
                  <div>
                    <p>TOTAL AMOUNT</p>
                    <h6>
                      £{totalData && PriceComponent(totalData.tran_amount)}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="invoice-footer">
              <button className="red-btn" onClick={() => setHide()}>
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default InvoiceGA;
