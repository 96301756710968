import React, { useState } from "react";
import "./index.scss";
import { useNavigate, Link } from "react-router-dom";
import getEndpoints from "../../../config/endpoints";
import axios from "axios";
import { PriceComponent, getToken, getUserData } from "../../../utils/helperFunctions";
import ConfirmationPromptModal from "../../../components/Shared/ConfirmationBox/confirmationPrompt";
const GroupAdminBuyCask = (props) => {
  let [modalShow, setModalShow] = useState(false);
  let [sellCaskData, setSellCaskData] = useState(props.caskData);
  let [totalShares, setTotalShares] = useState(props.caskData.total_shares);
  let [availableShares, setAvailableShares] = useState(
    props.caskData.available_shares
  );
  let [sharePrice, setSharePrice] = useState(props.caskData.share_price);
  let [shareSaleValue, setShareSaleValue] = useState(
    props.caskData.share_price * props.caskData.available_shares
  );
  const [deleteShow, setDeleteShow] = useState(false);
  let [deleteTrigger, setDeleteTrigger] = useState(false);
  const userData = getUserData();
  const token = getToken();
  let navigate = useNavigate();
  const modalTriggerHide = () => {
    setModalShow(false);
  };
  const PortfolioRedirector = () => {
    navigate("/groupadmin");
  };
  const availableSharesHandler = (val) => {
    setAvailableShares(val);
    setShareSaleValue(sharePrice * val);
  };
  const sharePriceHandler = (val) => {
    setSharePrice(val);
    setShareSaleValue(availableShares * val);
  };
  const sellCaskSharesHandler = async (event) => {
    let formData = new FormData();
    formData.append("cask_id", sellCaskData.cask_id);
    formData.append("user_id", userData.user_id);
    formData.append("total_shares", totalShares);
    formData.append("available_shares", availableShares);
    formData.append("share_price", sharePrice);

    try {
      const endpoint = getEndpoints().groupadmin.cask.sell;
      const response = await axios.post(endpoint, formData, {
        headers: { token: token },
      });
      modalTriggerHide();
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  return (
    <>
      <ConfirmationPromptModal
        show={modalShow}
        confirmFunc={sellCaskSharesHandler}
        close={modalTriggerHide}
      />
      <div className="caskHeader">
        <h6>SHARE DETAILS</h6>
        <div onClick={PortfolioRedirector} className="portfolioRedirector">
          <img src="assets/images/icons/left-arrow.svg" alt="" />
          {"<Back To Portfolio"}
        </div>
      </div>
      <div className="caskBody">
        <h6 className="caskBodyText">
          Please fill out the form to set available shares and price of the cask
          listed below.
        </h6>
        <div className="caskBodyContainer">
          <div className="caskRow">
            <h6>Cask :</h6>
            <p>
              {sellCaskData.distillery},{sellCaskData.casktype}
            </p>
          </div>
          <div className="caskRow">
            <h6>Cask Price :</h6>
            <p>£{PriceComponent(sellCaskData.price)}</p>
          </div>
          <div className="caskRow">
            <h6>Total Shares :</h6>
            <input
              type="number"
              id="total-shares"
              placeholder={sellCaskData.total_shares}
              onChange={(e) => setTotalShares(e.target.value)}
            />
          </div>
          <div className="caskRow">
            <h6>Available Shares :</h6>
            <input
              type="number"
              id="available-shares"
              placeholder={sellCaskData.available_shares}
              onChange={(e) => availableSharesHandler(e.target.value)}
            />
          </div>
          <div className="caskRow">
            <h6>Share Price (£) :</h6>
            <input
              type="number"
              id="share-price"
              placeholder={sellCaskData.share_price}
              onChange={(e) => sharePriceHandler(e.target.value)}
            />
          </div>
          <div className="caskRow">
            <h6>Shares for Sale :</h6>
            <p>£{PriceComponent(shareSaleValue)}</p>
          </div>
          <div className="sellFooter">
            <button className="greenBtn" onClick={() => setModalShow(true)}>
              SUBMIT
            </button>
            {/* <button className="greenBtn" onClick={sellCaskSharesHandler}>
              SUBMIT
            </button> */}
            <button className="redBtn" onClick={PortfolioRedirector}>
              CANCEL
            </button>
          </div>
        </div>
        <h6 className="caskBodyBottom">
          Once you click <b>Submit</b>, your cask shares will be available for
          your members to purchase from their <b>Portfolio</b>.
        </h6>
      </div>
    </>
  );
};
export default GroupAdminBuyCask;
