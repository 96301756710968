import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import { PriceComponent, getUserData } from "../../../utils/helperFunctions";
import getEndpoints from "../../../../src/config/endpoints";
import InvoiceGA from "../GroupAdminInvoice/index";

const GrpAdTransactions = () => {
  const [transactionList, setTransactionList] = useState([]);
  const [showInvoice, setShowInvoice] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);
  const [totalData, setTotalData] = useState(null);
  const [walletAction, setWalletAction] = useState(null);
  const profileData = getUserData();
  const triggerdDetailedView = (caskDetails,walletTransaction) => {
    setShowInvoice(true);
    setInvoiceData(caskDetails);
    setTotalData(walletTransaction);
  };
  const triggerdDetailedViewHide = () => {
    setShowInvoice(false);
  };
  const loadCaskRequestHandler = async () => {
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    try {
      const endpoint = getEndpoints().groupadmin.transaction;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      setTransactionList(response.data);

      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  useEffect(() => {
    loadCaskRequestHandler();
  }, []);
  return (
    <>
      <InvoiceGA
        setDisplay={showInvoice}
        invoiceData={invoiceData}
        onHide={triggerdDetailedViewHide}
        totalData={totalData}
        walletAction={walletAction}
      />
      <div>
        <table>
          <thead className="table-head">
            <tr>
              <th scope="col">NO.</th>
              <th scope="col">ACTION</th>
              <th scope="col">STATUS</th>
              <th scope="col">DATE</th>
              <th scope="col">AMOUNT</th>
              <th scope="col">INVOICE</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {transactionList &&
              transactionList.map((transaction, index) => {
                return (
                  <tr key={index}>
                    <td data-label="NO">{index + 1}</td>
                    <td data-label="ACTION">
                      {transaction.wallet_transaction.wallet_action}
                    </td>
                    <td data-label="STATUS">
                      {transaction.wallet_transaction.trans_status}
                    </td>
                    <td data-label="DATE">
                      {transaction.wallet_transaction.trans_date}
                    </td>
                    <td data-label="AMOUNT">
                      £
                      {PriceComponent(transaction.wallet_transaction.tran_amount)}
                    </td>
                    <td data-label="INVOICE">
                      <Button
                        className="greenButtonMain"
                        onClick={(e) =>
                          triggerdDetailedView(transaction.cask_details,transaction.wallet_transaction)
                        }
                      >
                        VIEW
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default GrpAdTransactions;
