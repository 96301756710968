import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const Auth = (props) => {
    const [isLoggedIn, setLogin] = useState(true);
    const location = useLocation();

    return (
        <>
            { !isLoggedIn ? (
                <Navigate to='/' state={ { from: location } } replace />
            ) : (
                props.children
            ) }
        </>
    );
};

export default Auth;
