import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import successImage from "../../../../assets/images/Success.png";
import errorImage from "../../../../assets/images/error.png";

import "./index.scss";
const BuyCaskModalMember = (props) => {
  const modalStatus = props.showStatus;
  const successStatus = modalStatus.success;
  const [modalBoxShow, setModalBoxShow] = useState(modalStatus.show);
  const triggerClose = props.dataFunction;
  useEffect(() => {
    setModalBoxShow(props.showStatus.show);
  }, [props]);
  return (
    <>
      <Modal show={modalBoxShow} className="ModalMemberBuyCask">
        <Modal.Body>
          <div className="modalBodyBuyCaskMember">
            {successStatus === true && (
              <>
                <div className="modalBodyContainer">
                  <img
                    src={successImage}
                    alt="image loading failed...."
                    className="modalBodySuccessImage"
                  />
                  <div className="modalBodyMainMemberBuyCask">
                    <div className="successTextMemberBuyCask">Success</div>
                  </div>
                  <div className="btnRow">
                    <button className="btnRowgreen" onClick={triggerClose}>
                      CLOSE
                    </button>
                  </div>
                </div>
              </>
            )}

            {successStatus === false && (
              <>
                <div className="modalBodyContainer">
                  <img
                    src={errorImage}
                    alt="image loading failed...."
                    className="modalBodySuccessImage"
                  />
                  <div className="modalBodyMainMemberBuyCask">
                    <div className="modalBodySuccessTextError">
                      Purchase Failed.....
                    </div>
                  </div>
                  <div className="btnRowError">
                    <button className="btnRowgreen" onClick={triggerClose}>
                      CLOSE
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default BuyCaskModalMember;
