import React, { useState } from "react";
import Logo from "../../../assets/images/logo-2.png";
import LeftArrow from "../../../assets/images/left-arrow.svg";
import "./resetPassword.scss";
import authenticationManagementAPIs from "../../../services/authentication.service";
import { useSearchParams,useNavigate } from "react-router-dom";
const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  
  const [emailErrors, setEmailErrors] = useState("");
  const [disableButton, setDisableButton] = useState(true);
  const [errors, setErrors] = useState(false);
  const [queryParameters] = useSearchParams();
  const token =queryParameters.get("token");
  let navigate = useNavigate();
 
  const submitHandler = async (e) => {
    e.preventDefault();
    
    let formData = new FormData();
    formData.append("password", password);
    formData.append("token", token);
    const response = await authenticationManagementAPIs.ResetPassword(
    
      formData
    );
    if(response.data.message == "success"){
      navigate("/");
    }
   
   
  };
  const password_match = (e) => {
    if (
      !password.match(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\$&*~]).{8,}$/
      )
    ) {
      setErrors(
        "* The password must have atleast 8 characters long which contain an upper case letter, a lower case letter, a number and a special character"
      );
      setDisableButton(true);
    } else {
      setErrors("");
      setDisableButton(false);
      if (cpassword.length > 0) {
        if (
          !password.match(
            /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\$&*~]).{8,}$/
          )
        ) {
          setErrors(
            "* The password must have atleast 8 characters long which contain an upper case letter, a lower case letter, a number and a special character"
          );
          setDisableButton(true);
        } else {
          if (password != cpassword) {
            setErrors("Passwords do not match");
            setDisableButton(true);
          } else {
            setErrors("");
            setDisableButton(false);
          }
        }
      }
    }
  };

  return (
    <main id="mainRest">
      <div className="containerReset">
        <div className="headerRest">
          <img src={Logo} alt="" />
          <div>
            <h4>Reset Password</h4>
            <p>
              Passwords must be at least 6 characters and contain at least 1
              number or special character
            </p>
          </div>
        </div>
        <div className="bodyReset">
          <div className="inputsReset">
            <input
              type="password"
              placeholder="New Password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyUp={(e) => password_match(e.target)}
              required
            />
            <input
              type="password"
              placeholder="Confirm Password"
              name="cpassword"
              onChange={(e) => setCPassword(e.target.value)}
              onKeyUp={(e) => password_match(e.target)}
              required
            />
          </div>
          <div className="errorReset">{errors}</div>
          <div className="containerBtnReset">
            <button disable={setDisableButton} onClick={submitHandler}>RESET PASSWORD</button>
            <div>
              <a href="/">
                <img src={LeftArrow} alt="" />
                back to login
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
export default ResetPassword;
