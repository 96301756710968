import React, { useEffect, useState } from "react";
import "./index.scss";
import SideImage from "../../../assets/images/withdraw-funds.png";
import { PriceComponent, getToken, getUserData } from "../../../utils/helperFunctions";
import getEndpoints from "../../../config/endpoints";
import axios from "axios";
import ConfirmationPromptModal from "../../../components/Shared/ConfirmationBox/confirmationPrompt";
import ProfileCard from "../../../components/Shared/ProfileCard/ProfileCard";
import leftIcon from "../../../assets/images/burgerIconLeft.svg";
import RightIcon from "../../../assets/images/burgerIconRight.svg";
import { useSelector, useDispatch } from "react-redux";
import { show, hide, sidebarStatus } from "../../../store/sidebar";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import UploadIcon from "../../Broker/BrokerAdminDashboard/resources/uploadIcon.svg";
import {
  isValid,
  isExpirationDateValid,
  isSecurityCodeValid,
  getCreditCardNameByNumber,
} from "creditcard.js";
import "../../../components/Shared/connectedAccountModal/index.scss";
import { useNavigate } from "react-router-dom";
const IndividualAdminWalletDeposit = () => {
  const openSidebar = useSelector(sidebarStatus);
  const dispatch = useDispatch();
  const [walletData, setWalletData] = useState(null);
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [val, setVal] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [expiryDate, setExpiryDate] = useState(null);
  const [expiryMonth, setExpiryMonth] = useState(null);
  const [expiryYear, setExpiryYear] = useState(null);
  const [cardNumber, setCardNumber] = useState(null);
  const [isInitial, setIsInitial] = useState(0);
  const [showConnectModal, setShowConnectModal] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [accountHolderType, setAccountHolderType] = useState("individual");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [city, setCity] = useState("");
  const [bankName, setBankName] = useState("");
  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState("");
  const [region, setRegion] = useState("");
  const profileData = getUserData();
  const [userData, setUserData] = useState(null);
  const [identityDocument, setIdentityDocument] = useState(null);
  const [errors,setErrors] = useState(null);
  const [dob, setDob] = useState(null);
  const [cvc, setCvc] = useState(null);
  const hiddenFileInput1 = React.useRef(null);
   let navigate = useNavigate();
  const triggerUploadIndividual = () => {
    hiddenFileInput1.current.click();
  };
  const walletInfo = async () => {
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    try {
      const endpoint = getEndpoints().wallet.walletInfo;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      setWalletData(response.data[0]);
      if (response.data.length == 0) {
        setShowConnectModal(true);
        setIsInitial(1);
      }
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  const inputValidator = (input) => {
    if (input.target.name == "expDate") {
      var pattern = /^[0-9]*\/*?[0-9]*$/;
      if (pattern.test(input.target.value) == true) {
        setExpiryDate(
          input.target.value.length === 3 && !input.target.value.includes("/")
            ? `${input.target.value.substring(
                0,
                2
              )}/${input.target.value.substring(2)}`
            : input.target.value
        );
        setExpiryMonth(expiryDate.substring(0, 2));
        setExpiryYear(expiryDate.substring(3));
      }
    } else if (input.target.name == "cardNumber") {
      if (isValid(input.target.value) == true) {
        setCardNumber(input.target.value);
      }
    } else if (input.target.name == "cvc") {
      if (isSecurityCodeValid(cardNumber, input.target.value) == true) {
        setCvc(input.target.value);
      }
    }
  };
  const withdrawInputValidator = (val) => {
    var pattern = /^[0-9]*\/*?[0-9]*$/;
    if (pattern.test(val) == true) {
      setWithdrawAmount(val);
    }
  };
  const withdrawHandler = () => {
    if (withdrawAmount !== "") {
      setShowModal(false);
      withdrawAmountHandler();
    }
  };

  const withdrawAmountHandler = async () => {
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    formData.append("deposit_amount", withdrawAmount);
    if (isInitial === 1) {
      formData.append("first_name", firstName);
      formData.append("last_name", lastName);
      formData.append("phone", phoneNumber);
      formData.append("country", country);
      formData.append("email", email);
      formData.append("business_type", "individual");
      formData.append("account_holder_name", accountHolderName);
      formData.append("account_holder_type", accountHolderType);
      formData.append("bank_name", bankName);
      formData.append("dob", dob);
      formData.append("address_line1", address);
      formData.append("address_line2", address);
      formData.append("city", city);
      formData.append("region", city);
      formData.append("postal_code", postcode);
      formData.append("card_nmbr", cardNumber);
      formData.append("exp_month", expiryMonth);
      formData.append("exp_year", expiryYear);
      formData.append("cvc", cvc);
      formData.append("isInitial", isInitial);
      formData.append("id_proof", identityDocument);
    }

    try {
      const endpoint = getEndpoints().wallet.deposit;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      setVal(val + 1);
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  const triggerModalHide = () => {
    setShowModal(false);
  };
  const triggerModalConnectlHide = () => {
    if (bankName !== "" && accountHolderName !== "" && accountHolderType !== "" && identityDocument !== "" ) {
      setErrors("");
      setShowConnectModal(false);
      
    } else {
      setErrors("Please Fill All Fields !")
    }
   
  };
  const userDetailsFetch = async () => {
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    try {
      const endpoint = getEndpoints().users.fech_user_details;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      setUserData(response.data[0]);

      setFirstName(response.data[0].first_name);
      setLastName(response.data[0].last_name);
      setPhoneNumber(response.data[0].phone_no);
      setDob(response.data[0].dob);
      setEmail(response.data[0].email);
      setCity(response.data[0].city);
      setCountry(response.data[0].country);
      setPostcode(response.data[0].postcode);
      setAddress(response.data[0].address);
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
   const handleClose = () => {
     setShowConnectModal(false);
     navigate("/individualadmin/wallet");
   };
  useEffect(() => {
    walletInfo();
    userDetailsFetch();
  }, [val]);
  return (
    <>
      <ConfirmationPromptModal
        show={showModal}
        confirmFunc={withdrawHandler}
        close={triggerModalHide}
      />
      <Modal
        show={showConnectModal}
        className="connectedAccountModal"
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Account Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6 className="subHeading">Personal Details</h6>
          <div className="labelDiv">
            <div>
              <label className="labelInput">First Name</label>
              <input type="text" value={firstName} readOnly />
            </div>
            <div>
              <label className="labelInput">Last Name</label>
              <input type="text" value={lastName} readOnly />
            </div>
          </div>
          <div className="labelDiv">
            <div>
              <label className="labelInput">Date Of Birth</label>
              <input type="text" className="smallWidthInput" value={dob} />
            </div>
            <div>
              <label className="labelInput">Email Address</label>
              <input
                type="text"
                className="mediumWidthInput"
                value={email}
                readOnly
              />
            </div>
          </div>
          <div className="labelDiv">
            <div>
              <label className="labelInput">Country</label>
              <input type="text" value={country} readOnly />
            </div>
            <div>
              <label className="labelInput">Phone Number</label>
              <input type="text" value={phoneNumber} readOnly />
            </div>
          </div>

          <h6 className="subHeading">Bank Details</h6>
          <div className="labelDiv">
            <div>
              <label className="labelInput">Account Holder Type</label>

              <select
                className="dropDownInput"
                onSelect={(e) => setAccountHolderType(e.target.value)}
              >
                <option value="individual">Individual</option>
                <option value="company">Company</option>
              </select>
            </div>
            <div>
              <label className="labelInput">Account Holder Name</label>
              <input
                type="text"
                onChange={(e) => setAccountHolderName(e.target.value)}
              />
            </div>
          </div>
          <div className="labelDiv">
            <div>
              <label className="labelInput">Account Number</label>
              <input
                type="text"
                className="fullWidthInput"
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            </div>
          </div>
          <div className="labelDiv">
            <div>
              <label className="labelInput ">Bank Name</label>
              <input
                type="text"
                className="fullWidthInput"
                onChange={(e) => setBankName(e.target.value)}
              />
            </div>
          </div>
          <h6 className="subHeading">Address Details</h6>
          <div className="labelDiv">
            <div>
              <label className="labelInput">Address (First Line)</label>
              <input
                type="text"
                className="fullWidthInput"
                value={address}
                readOnly
              />
            </div>
          </div>
          <div className="labelDiv">
            <div>
              <label className="labelInput">Address (Second Line)</label>
              <input
                type="text"
                className="fullWidthInput"
                value={city}
                readOnly
              />
            </div>
          </div>
          <div className="labelDiv">
            <div>
              <label className="labelInput">Postcode</label>
              <input
                type="text"
                className="smallWidthInput"
                value={postcode}
                readOnly
              />
            </div>
            <div>
              <label className="labelInput">Region</label>
              <input
                type="text"
                className="smallWidthInput"
                value={city}
                readOnly
              />
            </div>
            <div>
              <label className="labelInput">City</label>
              <input
                type="text"
                className="smallWidthInput"
                value={city}
                readOnly
              />
            </div>
          </div>
          <div className="labelDiv">
            <div className="identityUpload">
              <input
                type="file"
                accept=".pdf"
                style={{ display: "none" }}
                ref={hiddenFileInput1}
                onChange={(event) => setIdentityDocument(event.target.files[0])}
              />
              <Button
                className="PrivateUploadButton"
                onClick={triggerUploadIndividual}
              >
                <div className="PrivateuploadIcon">
                  <img src={UploadIcon} />
                </div>
                <div className="uploadIconText">Upload KYC</div>
              </Button>
            </div>
          </div>
          <div className="labelDiv">
            <div className="errors">{errors}</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={triggerModalConnectlHide}
            className="accountConnectBtn"
          >
            SUBMIT
          </Button>
          {/* <Button variant="danger" onClick={close}>
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>
      <div className="mainContainerOuter">
        <main
          id="mainDashboard"
          className={openSidebar ? "main" : "mainContainer"}
        >
          <div className="row">
            <div className="pagetitle">
              <div className="sideBarIconMain">
                {openSidebar == true && (
                  <button
                    onClick={() => dispatch(hide())}
                    className="leftButton"
                  >
                    <img src={leftIcon} />
                  </button>
                )}
                {openSidebar == false && (
                  <button
                    onClick={() => dispatch(show())}
                    className="rightButton"
                  >
                    <img src={RightIcon} />
                  </button>
                )}
              </div>
              <h1 className="mainHeadingSub">Deposit Funds</h1>
              <nav className="SubHeading">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/individualadmin/wallet">{"< Back to Wallet"}</a>
                  </li>
                </ol>
              </nav>
            </div>
            <ProfileCard />
          </div>
          <section className="wallet">
            <div className="walletRow">
              <div className="depositContainer">
                <div className="depositRow">
                  <label htmlFor="amt">Select Amount</label>
                  <div id="amt" className="amt">
                    <input
                      type="text"
                      className="card-input"
                      placeholder="£1,000.00"
                      value={withdrawAmount}
                      onChange={(e) => withdrawInputValidator(e.target.value)}
                    />
                    <select name="type" id="type" className="card-input remove">
                      <option value="">GBP</option>
                    </select>
                  </div>
                </div>
                <div className="depositRow">
                  <label htmlFor="payment">Payment Method</label>
                  <div id="payment" className="payment">
                    <select name="methods" id="methods" className="card-input">
                      <option value="">Credit / Debit Card</option>
                    </select>
                  </div>
                </div>
                <div className="depositRow">
                  <div className="paymentContainer">
                    <div className="payment-img">
                      <img src="assets/images/favicon.png" alt="" />
                      <img src="assets/images/favicon.png" alt="" />
                      <img src="assets/images/favicon.png" alt="" />
                      <img src="assets/images/favicon.png" alt="" />
                    </div>
                  </div>
                </div>
                <div className="depositRow">
                  <input
                    type="text"
                    placeholder="Credit Card Number"
                    className="card-input"
                    name="cardNumber"
                    maxLength={17}
                    onKeyDown={(e) => inputValidator(e)}
                  />
                  <div className="payCard">
                    <input
                      type="text"
                      placeholder="MM /YYYY"
                      className="card-input"
                      maxLength="7"
                      id="inputExpDate"
                      name="expDate"
                      onChange={(e) => inputValidator(e)}
                      value={expiryDate}
                    />
                    <input
                      type="text"
                      name="cvc"
                      id=""
                      placeholder="CVC"
                      className="card-input cvv"
                      onChange={(e) => inputValidator(e)}
                    />
                  </div>
                </div>
                <div className="depositRow">
                  <button
                    className="submitButton"
                    onClick={() => setShowModal(true)}
                  >
                    SUBMIT
                  </button>
                </div>
              </div>

              <div className="depositImg">
                <img src={SideImage} alt="" />
              </div>
            </div>

            {walletData && (
              <div className="walletBalanceContainer">
                <h4>Wallet Balance</h4>
                <div className="walletBalance">
                  <div>
                    <h5>
                      {" "}
                      {walletData.available_amt ? "£ " : ""}
                      {PriceComponent(walletData.available_amt)}
                    </h5>
                    <span>Available</span>
                  </div>
                  <p>+</p>
                  <div>
                    <h5>
                      {" "}
                      {walletData.pending_amount ? "£ " : ""}
                      {PriceComponent(walletData.pending_amount)}
                    </h5>
                    <span>Pending</span>
                  </div>
                  <p>=</p>
                  <div>
                    <h5>
                      {" "}
                      {walletData.total_amt ? "£ " : ""}
                      {PriceComponent(walletData.total_amt)}
                    </h5>
                    <span>Total Funds</span>
                  </div>
                </div>
              </div>
            )}
          </section>
        </main>
      </div>
    </>
  );
};

export default IndividualAdminWalletDeposit;
