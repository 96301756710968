import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { PriceComponent } from "../../../utils/helperFunctions";

const CaskRequestModal = (props) => {
  const caskData = props.data;
  const onHide = props.onHide;
  let navigate = useNavigate();
  const UploadHandler = () => {
    navigate("/broker/upload_private");
  };
  return (
    <>
      <Modal show={props.show} className="caskRequestModalOuter">
        <Modal.Body>
          <div className="CaskRequestModal">
            <div className="CaskRequestHeading">
              <div className="CaskRequestHeadingLabel">Cask Request :</div>
              <div className="CaskRequestHeadingText">
                {caskData.first_name + " " + caskData.last_name}
              </div>
            </div>
            <div className="CaskRequestDataSection">
              <div className="CaskRequestDataSet">
                Type :{" "}
                <div className="CaskRequestDataValue">{caskData.type}</div>
              </div>
              <div className="CaskRequestDataSet">
                Budget :
                <div className="CaskRequestDataValue">
                  £{caskData.budget && PriceComponent(caskData.budget)}
                </div>
              </div>
              <div className="CaskRequestDataSet">
                Purpose :
                <div className="CaskRequestDataValue">{caskData.purpose}</div>
              </div>
              <div className="CaskRequestDataSet">
                Age Range :
                <div className="CaskRequestDataValue">
                  {caskData.age_from + "-" + caskData.age_to + " Years"}
                </div>
              </div>
              <div className="CaskRequestDataSet">
                Hold Period :
                <div className="CaskRequestDataValue">
                  {caskData.hold_period}
                </div>
              </div>
              <div className="CaskRequestDataSet">
                Distillery :
                <div className="CaskRequestDataValue">
                  {caskData.distillery}
                </div>
              </div>
              <div className="CaskRequestDataSet">
                Cask Type :
                <div className="CaskRequestDataValue">{caskData.cask_type}</div>
              </div>
              <div className="CaskRequestDataSet">
                {" "}
                Cask Size :
                <div className="CaskRequestDataValue">{caskData.cask_size}</div>
              </div>
              <div className="CaskRequestDataSet">
                No of Casks :
                <div className="CaskRequestDataValue">
                  {caskData.no_of_cask}
                </div>
              </div>
            </div>
          </div>
          <div className="CaskRequestButtonRow">
            <div className="CaskRequestUploadButton">
              <Button
                className="greenButtonCaskRequest"
                onClick={UploadHandler}
              >
                UPLOAD
              </Button>
            </div>
            <div className="CaskRequestCloseButton">
              <Button className="redButtonCaskRequest" onClick={onHide}>
                CLOSE
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default CaskRequestModal;
