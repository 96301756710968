import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "./index.scss";
const Menu = ({ item }) => {
  const [subnav, setSubnav] = useState(false);
  const showSubnav = () => {
    setSubnav(!subnav);
  };
  const indicator = window.location.pathname;
  return (
    <>
      <div className="sidebar-brkr-2">
        <NavLink
          to={item.path}
          className="sideBarLinkBrkr"
          onClick={item.subNav && showSubnav}
        >
          <div className="nav-link-brkr">
            {item.icon}
            <span className="sideBarLabelBrkr">{item.title}</span>
            <div
              className={
                item.path && item.path == indicator ? "show-indicator" : " "
              }
            ></div>
            {item.subNav && subnav
              ? item.iconOpened
              : item.subNav
              ? item.iconClosed
              : null}
          </div>
        </NavLink>

        {subnav &&
          item.subNav.map((item, index) => {
            return (
              <div className="dropDownLinkBrkr" key={index}>
                <NavLink
                  to={item.path}
                  className={
                    item.path && item.path == indicator
                      ? "dropDownLinkBrkr-c show-indicator-c"
                      : "dropDownLinkBrkr-c "
                  }
                  key={index}
                >
                  {item.icon}
                  <span className="sideBarSubLabelBrkr">{item.title}</span>
                </NavLink>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Menu;
