import React, { useEffect, useState } from "react";
import authenticationManagementAPIs from "../../../services/authentication.service";
import { PriceComponent, getToken, getUserData } from "../../../utils/helperFunctions";
import UnLikeIcon from "./images/favourite-heart-svgrepo-com.svg";
import LikeIcon from "./images/favourite-svgrepo-com.svg";

const PublicMarketPlace = () => {
  const [marketPublicData, setMarketPublicData] = useState([]);
  const userData = getUserData();
  const token = getToken();
  const loadPublicMarketData = async () => {
    let formData = new FormData();
    formData.append("user_id", JSON.stringify(userData.user_id));
    const response = await authenticationManagementAPIs.GMPublicMarketplace(
      token,
      formData
    );
    setMarketPublicData(response.data);
  };
  //Like update api
  const triggerLike = async (isLike, caskId) => {
    let formData = new FormData();
    formData.append("user_id", JSON.stringify(userData.user_id));
    formData.append("isLike", isLike);
    formData.append("caskId", caskId);
    const response =
      await authenticationManagementAPIs.GMPublicMarketplaceLikeUpdate(
        token,
        formData
      );
    loadPublicMarketData();
  };
  useEffect(() => {
    loadPublicMarketData();
  }, []);
  return (
    <>
      <div>
        <table>
          <thead className="table-head">
            <tr>
              <th scope="col">DISTILLERY</th>
              <th scope="col">AYS</th>
              <th scope="col">CASK TYPE</th>
              <th scope="col">LOA</th>
              <th scope="col">ABV</th>
              <th scope="col">OLA/RLA</th>
              <th scope="col">PRICE</th>
              {/* <th scope="col">LIKE</th> */}
              <th scope="col">ACTION</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {marketPublicData &&
              marketPublicData.map((marketData, index) => {
                return (
                  <tr key={index}>
                    <td data-label="DISTILLERY">{marketData.distillery}</td>
                    <td data-label="AYS">{marketData.ays}</td>
                    <td data-label="CASKTYPE">{marketData.casktype}</td>
                    <td data-label="LOA">{marketData.loa}</td>
                    <td data-label="ABV">{marketData.abv} %</td>
                    <td data-label="OLA/RLA">{marketData.ola_rla}</td>
                    <td data-label="PRICE">
                      £{PriceComponent(marketData.price)}
                    </td>
                    <td data-label="ACTION">
                      <div className="likeDataDivImg">
                        {marketData.islike == null && (
                          <img
                            src={UnLikeIcon}
                            className="likeIconImg"
                            onClick={() => triggerLike(1, marketData.cask_id)}
                          />
                        )}
                        {marketData.islike == 0 && (
                          <img
                            src={UnLikeIcon}
                            className="likeIconImg"
                            onClick={() => triggerLike(1, marketData.cask_id)}
                          />
                        )}
                        {marketData.islike == 1 && (
                          <img
                            src={LikeIcon}
                            className="likeIconImg"
                            onClick={() => triggerLike(0, marketData.cask_id)}
                          />
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default PublicMarketPlace;
