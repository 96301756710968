import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import successImage from "../../../../assets/images/Success.png";
import errorImage from "../../../../assets/images/error.png";
import { useNavigate } from "react-router-dom";

import "./index.scss";
const BuyCaskModal = (props) => {
  let navigate = useNavigate();
  const modalStatus = props.showStatus;
  const successStatus = modalStatus.success;
  const [modalBoxShow, setModalBoxShow] = useState(modalStatus.show);
  const triggerClose = props.dataFunction;
  const WalletRedirectHandler = () => {
    navigate("/individualadmin/wallet");
  };
  useEffect(() => {
    setModalBoxShow(props.showStatus.show);
  }, [props]);
  return (
    <>
      <Modal show={modalBoxShow} className="ModalOuterDiv">
        <Modal.Body>
          <div className="modalBody">
            {successStatus === true && (
              <>
                <div className="modalBodyContainer">
                  <img
                    src={successImage}
                    alt="image loading failed...."
                    className="modalBodySuccessImage"
                  />
                  <div className="modalBodyMain">
                    <div className="modalBodySuccessText">Success</div>
                    <div className="modalBodySuccessTagLine">
                      Your funds have been moved to pending. We will confirm the
                      purchase and notify you accordingly
                    </div>
                  </div>
                  <div className="btnRow">
                    <button className="btnRowgreen" onClick={triggerClose}>
                      CLOSE
                    </button>
                  </div>
                </div>
              </>
            )}

            {successStatus === false && (
              <>
                <div className="modalBodyContainer">
                  <img
                    src={errorImage}
                    alt="image loading failed...."
                    className="modalBodySuccessImage"
                  />
                  <div className="modalBodyMain">
                    <div className="modalBodySuccessTextError">
                      Insufficient Funds
                    </div>
                    <div className="modalBodySuccessTagLineError">
                      Please deposit further funds in your wallet before
                      purchasing.
                    </div>
                  </div>
                  <div className="btnRowError">
                    <button
                      className="btnRedUpload"
                      onClick={WalletRedirectHandler}
                    >
                      WALLET
                    </button>
                    <button className="btnRedCancel" onClick={triggerClose}>
                      CLOSE
                    </button>
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default BuyCaskModal;
