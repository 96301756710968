import React, { useState, useEffect } from "react";
import authenticationManagementAPIs from "../../../services/authentication.service";
import { getToken, getUserData } from "../../../utils/helperFunctions";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./index.scss";
const BorkerPayoutRequest = () => {
  const [responseDatas, setResponseDatas] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [walletTransData, setWalletTransData] = useState(null);
  const [actionKey, setActionKey] = useState(null);
  const userData = getUserData();
  const token = getToken();
  let formData = new FormData();
  formData.append("user_id", userData.user_id);
  const fetchData = async () => {
    try {
      const res = await authenticationManagementAPIs.SuperAdminPayOutView(
        token,
        formData
      );
      if (JSON.stringify(res.data.userData)) {
        setResponseDatas(res.data.userData);
      }
    } catch (e) {
      return e.response || e;
    }
  };

  const payoutConfirmationHandler = (walletTransDatas, key) => {
    setShowModal(true);
    setWalletTransData(walletTransDatas);

    setActionKey(key);
  };
  const payoutActionHandler = async (withdrawData, key) => {
    let formData = new FormData();
    formData.append("user_id", userData.user_id);
    formData.append("wallet_trans_id", withdrawData.wallet_trans_id);
    formData.append("trans_amt", withdrawData.tran_amount);
    formData.append("member_id", withdrawData.user_id);
    formData.append("key", key);
    const res = await authenticationManagementAPIs.SuperAdminPayOutAction(
      token,
      formData
    );
    if (res.data) {
      confirmationHide();
      fetchData();
    }
  };
  const confirmationHide = () => {
    setShowModal(false);
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Modal show={showModal} onHide={confirmationHide}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm ..</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you Sure, You want to continue?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => payoutActionHandler(walletTransData, actionKey)}
          >
            Confirm
          </Button>
          <Button variant="danger" onClick={confirmationHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="payoutRequestBroker">
        <table>
          <thead className="table-head">
            <tr className="payoutTHead">
              <th scope="col" className="noColumn">
                No.
              </th>
              <th scope="col">NAME</th>
              <th scope="col">USER TYPE</th>
              <th scope="col">REQUESTED DATE</th>
              <th scope="col">AMOUNT</th>
              <th scope="col">AVAILABLE AMOUNT</th>
              <th scope="col" className="actionColumn">
                ACTION
              </th>
            </tr>
          </thead>
          <tbody className="table-body">
            {responseDatas &&
              responseDatas.map((responseData, index) => (
                <tr key={responseData.cask_id} className="portfolioBrkrDataRow">
                  <td data-label="Name" className="noColumn">
                    {index + 1}
                  </td>
                  <td data-label="Name">
                    {responseData.first_name + " " + responseData.last_name}
                  </td>
                  <td data-label="UserType">{responseData.user_type}</td>
                  <td data-label="RequestedDate">{responseData.trans_date}</td>
                  <td data-label="Amount">{responseData.tran_amount}</td>
                  <td data-label="AvailableAmount">
                    {responseData.available_amt}
                  </td>
                  <td data-label="ACTION" className="actionColumn">
                    <div className="buttonRowMemberList">
                      <button
                        className="viewGreenbtn"
                        onClick={() =>
                          payoutConfirmationHandler(responseData, "approved")
                        }
                      >
                        APPROVE
                      </button>

                      <button
                        className="deleteRedbtn"
                        onClick={() =>
                          payoutConfirmationHandler(responseData, "denied")
                        }
                      >
                        DENY
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default BorkerPayoutRequest;
