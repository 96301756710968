import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import walletImage from "../../../assets/images/wallet.png";
import { PriceComponent, getToken, getUserData } from "../../../utils/helperFunctions";
import DepositIcon from "../../../assets/images/wallet-deposit.svg";
import WithdrawIcon from "../../../assets/images/wallet-withdraw.svg";
import getEndpoints from "../../../config/endpoints";
import axios from "axios";
import './index.scss';
const WalletDeposit = () => {
    const [walletData, setWalletData] = useState(null);
    const profileData = getUserData();
    const walletInfo = async () => {
      let formData = new FormData();
      formData.append("user_id", profileData.user_id);
      try {
        const endpoint = getEndpoints().wallet.walletInfo;
        const response = await axios.post(endpoint, formData, {
          headers: { token: profileData.token },
        });
        setWalletData(response.data[0]);
        return response;
      } catch (e) {
        return e.response || e;
      }
    };
    useEffect(() => {
      walletInfo();
    }, []);
    let navigate = useNavigate();
    const withdrawHandle = () => {
       navigate("/individualadmin/wallet/withdraw");
    };
    const depositHandle = () => {
      navigate("/individualadmin/wallet/deposit");
    };
    return (
      <div>
        <div className="fundsContainerGA">
          <div className="funds">
            <div className="deposit fund">
              <button className="btnDeposit" onClick={depositHandle}>
                <img src={DepositIcon} alt="" />
                Deposit Funds
              </button>
              <div className="content">
                <h4>Deposit</h4>
                <p>
                  Deposit funds to your BitCask wallet. Click{" "}
                  <b>Deposit Funds</b> and enter your card details to
                  immediately top up your wallet. The funds will be reflected in
                  your
                  <b> Available Balance</b>.
                </p>
              </div>
            </div>
            <div className="fund">
              <button className="btnWithdraw" onClick={withdrawHandle}>
                <img src={WithdrawIcon} alt="" />
                Withdraw Funds
              </button>

              <div className="content">
                <h4>Withdraw</h4>
                <p>
                  Withdraw funds from your BitCask wallet. Click{" "}
                  <b>Withdraw Funds</b>and select the amount you wish to take
                  from your <b>Available Balance</b>. You will receive the funds
                  in your selected bank account.
                </p>
              </div>
            </div>
          </div>
          <div className="fundsImg">
            <img src={walletImage} alt="" />
          </div>
        </div>
        {walletData && (
          <div className="walletBalanceContainer">
            <h4>Wallet Balance</h4>
            <div className="walletBalance">
              <div>
                <h5>
                  {" "}
                  {walletData.available_amt ? "£ " : ""}
                  {PriceComponent(walletData.available_amt)}
                </h5>
                <span>Available</span>
              </div>
              <p>+</p>
              <div>
                <h5>
                  {walletData.pending_amount ? "£ " : ""}
                  {PriceComponent(walletData.pending_amount)}
                </h5>
                <span>Pending</span>
              </div>
              <p>=</p>
              <div>
                <h5>
                  {walletData.total_amt ? "£ " : ""}
                  {PriceComponent(walletData.total_amt)}
                </h5>
                <span>Total Funds</span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
}
export default WalletDeposit;