import React, { useState } from "react";
import MemberList from "./memberList";
import ProfileCard from "../../../components/Shared/ProfileCard/ProfileCard";
import plusIcon from "../../../assets/images/plus.svg";
import "./index.scss";
import leftIcon from "../../../assets/images/burgerIconLeft.svg";
import RightIcon from "../../../assets/images/burgerIconRight.svg";
import { useSelector, useDispatch } from "react-redux";
import { show, hide, sidebarStatus } from "../../../store/sidebar";

const GroupAdminMemberList = () => {
  const openSidebar = useSelector(sidebarStatus);
  const dispatch = useDispatch();
  const [modalStatus, setModalStatus] = useState(false);

  const memberAddHandler = () => {
    setModalStatus(true);
  };
  const modalHideTrigger = () => {
    setModalStatus(false);
  };

  return (
    <>
      <div className="mainContainerOuter">
        <main
          id="mainDashboard"
          className={openSidebar ? "main" : "mainContainer"}
        >
          <div className="page-header">
            <div className="pagetitleAddmember">
              <div className="sideBarIconMain">
                {openSidebar == true && (
                  <button
                    onClick={() => dispatch(hide())}
                    className="leftButton"
                  >
                    <img src={leftIcon} />
                  </button>
                )}
                {openSidebar == false && (
                  <button
                    onClick={() => dispatch(show())}
                    className="rightButton"
                  >
                    <img src={RightIcon} />
                  </button>
                )}
              </div>
              <h1>Members List</h1>
            </div>
            <div className="memberAdd" onClick={memberAddHandler}>
              <div>
                ADD
                <img src={plusIcon} alt="add" />
              </div>
            </div>
            <ProfileCard />
          </div>
          <section className="section dashboard">
            <MemberList
              modalStatus={modalStatus}
              hideTrigger={modalHideTrigger}
            />
          </section>
        </main>
      </div>
    </>
  );
};

export default GroupAdminMemberList;
