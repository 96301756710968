import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "./index.scss";

function ConfirmationPrompt(props) {
  const show = props.show;
  const close = props.close;
  const confirmFunc = props.confirmFunc;
 
  return (
    <div
      className="modal show "
      style={{ display: "block", position: "initial",backgroundColor: "#FFFF00" }}
    >
      <Modal show={show} onHide={close} className="confirmationAddMember">
        <Modal.Header closeButton>
          <Modal.Title>Confirm ..</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you Sure, You want to continue?</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={confirmFunc}>
            Confirm
          </Button>
          <Button variant="danger" onClick={close}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ConfirmationPrompt;
