import React, { useState } from "react";
import Logo from "../../../assets/images/logo-2.png";
import LeftArrow from "../../../assets/images/left-arrow.svg";

import authenticationManagementAPIs from "../../../services/authentication.service";
import "./index.scss";
const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailErrors, setEmailErrors] = useState("");
  const [disableButton, setDisableButton] = useState(true);
  const [error, setError] = useState(false);
  const submitHandler = async (e) => {
    e.preventDefault();
    if (email === "") {
      setError(true);
      setEmailErrors("* Please provide a valid Email Id");
      setDisableButton(true);
    }else{
        setError(false);
    }
    if (emailErrors !== "") {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
    let formData = new FormData();
    formData.append("email", email);
    const response = await authenticationManagementAPIs.ForgotPassword(
    
      formData
    );
    if (response.data === "failed") {
      setEmailErrors("* Email not found")
    }

  };
  const validateEmail = (e) => {
    setEmail(e.value);
    if (
      e.value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
      setEmailErrors("");
      setDisableButton(false);
      setError(false);
    } else {
      setEmailErrors("* Please provide a valid Email Id");
      setDisableButton(true);
    }
  };
  return (
    <main id="mainForgot">
      <div className="containerForgot">
        <div className="headerForgot">
          <img src={Logo} alt="" className="imgForgot" />
          <div className="headDivForgot">
            <h4 className="">Forgot Password</h4>
            <p>
              Enter your email and we'll send you a link to reset your password
            </p>
          </div>
        </div>
        <form action="">
          <div className={error == true ? "inputsError" : "inputs"}>
            <input
              type="text"
              placeholder="abcd@gmail.com"
              name="email"
              required
              onChange={(e) => validateEmail(e.target)}
            />
            <div className="invalid-error">{emailErrors}</div>
          </div>
          <div className="containerBtnForgot">
            <button disabled={disableButton}
              onClick={submitHandler}
              className={
                disableButton == true ? "forgotBtnDisable" : "forgotBtn"
              }
            >
              SUBMIT
            </button>
            <div>
              <a href="/">
                <img src={LeftArrow} alt="" />
                back to login
              </a>
            </div>
          </div>
        </form>
      </div>
    </main>
  );
};
export default ForgotPassword;
