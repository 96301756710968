import React, { useState, useEffect } from "react";
import "./index.scss";
import { useParams, useNavigate } from "react-router-dom";
import { PriceComponent, getToken, getUserData } from "../../../utils/helperFunctions";
import getEndpoints from "../../../config/endpoints";
import axios from "axios";
import BuyCaskModal from "./BuyCaskModal/BuyCaskModal";
const GroupAdminBuyCask = (props) => {
  let [buycaskData, setBuycaskData] = useState(props.caskData);
  let [showBuyCaskModal, setShowBuyCaskModal] = useState({
    show: false,
    success: false,
  });
  const [walletData, setWalletData] = useState(null);
  const { type } = useParams();
  let navigate = useNavigate();
  const profileData = getUserData();
  const PortfolioRedirector = () => {
    if (type == "public") {
      navigate("/groupadmin/marketplace/public");
    } else {
      navigate("/groupadmin/marketplace/private");
    }
  };
  const pageTitle = type == "public" ? "Public" : "Private";

  const walletInfo = async () => {
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    try {
      const endpoint = getEndpoints().wallet.walletInfo;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      setWalletData(response.data[0]);
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  const buyCaskHandler = async () => {
    if (
      walletData.available_amt -
        (buycaskData.price + (buycaskData.price / 100) * 10) <
      0
    ) {
      setShowBuyCaskModal({
        show: true,
        success: false,
      });
      return;
    }
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    formData.append("cask_id", buycaskData.cask_id);
    formData.append(
      "total_purchase_price",
      buycaskData.price + (buycaskData.price / 100) * 10
    );

    try {
      const endpoint = getEndpoints().groupadmin.cask.buy;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      if (response.data.success == "True") {
        setShowBuyCaskModal({
          show: true,
          success: true,
        });
      } else {
        setShowBuyCaskModal({
          show: true,
          success: false,
        });
      }
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  useEffect(() => {
    walletInfo();
  }, []);

  return (
    <>
      <BuyCaskModal
        showStatus={showBuyCaskModal}
        dataFunction={PortfolioRedirector}
      />
      <div className="caskHeader">
        <h6>CASK DETAILS</h6>
        <div onClick={PortfolioRedirector} className="portfolioRedirector">
          <img src="assets/images/icons/left-arrow.svg" alt="" />
          {"<Back To " + pageTitle + " Portfolio"}
        </div>
      </div>
      <div className="caskBody">
        <div className="caskBuyContainer ">
          <div className="caskRow">
            <p>Details</p>
          </div>
          <div className="caskRow">
            <h6>Cask :</h6>
            <p>
              {buycaskData.distillery}, {buycaskData.casktype}
            </p>
          </div>
          <div className="caskRow">
            <h6>AYS :</h6>
            <p>{buycaskData.ays}</p>
          </div>
          <div className="caskRow">
            <h6>ABV :</h6>
            <p>{buycaskData.abv} %</p>
          </div>
          <div className="caskRow">
            <h6>OLA :</h6>
            <p>{buycaskData.ola_rla}</p>
          </div>
          <div className="caskRow">
            <p>Cost Breakdown</p>
          </div>
          <div className="caskRow">
            <h6>Cask Price :</h6>
            <p>£{PriceComponent(buycaskData.price)}</p>
          </div>
          <div className="caskRow">
            <h6>Transaction Fee :</h6>
            <p>£{PriceComponent(((buycaskData.price / 100) * 10))}</p>
          </div>
          <div className="caskRow">
            <h6>Total Purchase Price:</h6>
            <p>
              £
              {PriceComponent((
                buycaskData.price +
                (buycaskData.price / 100) * 10
              ))}
            </p>
          </div>
          <div className="caskRow">
            <p>Balance</p>
          </div>
          <div className="caskRow">
            <h6>Available Balance :</h6>
            <p>£{walletData && walletData.available_amt.toLocaleString()}</p>
          </div>
          <div className="caskRow">
            <h6>New Balance :</h6>
            <p>
              {walletData &&
                walletData.available_amt -
                  (buycaskData.price + (buycaskData.price / 100) * 10) <
                  0 && (
                  <div className="minusBalanceText">
                    £
                    {walletData &&
                      PriceComponent(walletData.available_amt -
                        (buycaskData.price + (buycaskData.price / 100) * 10))}
                  </div>
                )}
              {walletData &&
                walletData.available_amt -
                  (buycaskData.price + (buycaskData.price / 100) * 10) >
                  0 && (
                  <div>
                    £
                    {walletData &&
                      PriceComponent(
                        walletData.available_amt -
                        (buycaskData.price + (buycaskData.price / 100) * 10)
                      )}
                  </div>
                )}
            </p>
          </div>
          <div className="sellFooter">
            <button className="greenBtn" onClick={buyCaskHandler}>
              PURCHASE
            </button>
            <button className="redBtn" onClick={PortfolioRedirector}>
              CANCEL
            </button>
          </div>
        </div>
        <h6 className="caskBodyBottom">
          By clicking <b>Purchase</b>, you agree to BitCask's{" "}
          <b>
            <a href="https://www.bit-cask.com/tc" className="" target="_blank">
              terms and conditions
            </a>
          </b>
          . Your payment will be put in pending until we secure your cask. If
          successful, your cask will be available to view in your{" "}
          <b>Portfolio</b>. If unavailable you will be fully refunded.
        </h6>
      </div>
    </>
  );
};
export default GroupAdminBuyCask;
