import React from "react";
import logo from "./Menu/images/logo.png";
import Menu from "./Menu";
import { SidebarData } from "./Menu/data";
import { useSelector } from "react-redux";
import { sidebarStatus } from "../../store/sidebar";
import "./index.scss";

const GroupAdminSideBar = () => {
  const openSidebar = useSelector(sidebarStatus);
  return (
    <>
      <aside
        className={openSidebar ? "nav-menu-broker" : "nav-menu-broker active"}
      >
        <div className="nav-menu-wrapper">
          <div className="brkr-nav-logo-sec">
            <img className="nav-logo" src={logo} alt="logo" />
            <p className="nav-p">BitCask</p>
          </div>
          <div className="sidebar-brkr">
            {SidebarData.map((item, index) => {
              return <Menu item={item} key={index} />;
            })}
          </div>
        </div>
      </aside>
    </>
  );
};
export default GroupAdminSideBar;
