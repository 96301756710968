import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { getToken, getUserData } from "../../../../utils/helperFunctions";
import authenticationManagementAPIs from "../../../../services/authentication.service";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./index.scss";
import walletImage from "../../../../assets/images/wallet.png";
const GAMembershipVotingCreatePoll = () => {
  const [title, setTitle] = useState("");
  const [question, setQuestions] = useState("");
  const [option1, setOption1] = useState("");
  const [option2, setOption2] = useState("");
  const [option3, setOption3] = useState("");
  const [option4, setOption4] = useState("");
  const [timePeriod, setTimePeriod] = useState("");
  const userData = getUserData();
  const user_id = JSON.stringify(userData.user_id);
  const validateField = (e) => {
    if (e.target.name == "titile") {
      setTitle(e.target.value);
    } else if (e.target.name == "question") {
      setQuestions(e.target.value);
    } else if (e.target.name == "option1") {
      setOption1(e.target.value);
    } else if (e.target.name == "option2") {
      setOption2(e.target.value);
    } else if (e.target.name == "option3") {
      setOption3(e.target.value);
    } else if (e.target.name == "option4") {
      setOption4(e.target.value);
    } else if (e.target.name == "timePeriod") {
      let newDate = new Date();
      const selectedDate = new Date(e.target.value);
      if (selectedDate > newDate) {
        setTimePeriod(e.target.value);
      }
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      title !== "" &&
      question !== "" &&
      option1 !== "" &&
      option2 !== "" &&
      option3 !== "" &&
      option4 !== "" &&
      timePeriod !== ""
    ) {
      const token = getToken();

      const body = {
        title,
        option1,
        option2,
        option3,
        option4,
        timePeriod,
        user_id,
        question,
      };
      const responseData =
        await authenticationManagementAPIs.MembershipVotingCreate(token, body);
      if (responseData.data.success == true) {
        setDefault(e);
      }
    }
  };
  const setDefault = (e) => {
    e.preventDefault();
    setTitle("");
    setQuestions("");
    setOption1("");
    setOption2("");
    setOption3("");
    setOption4("");
    setTimePeriod("");
  };

  return (
    <>
      <section className="membershipVotingCreate">
        <Tabs
          defaultActiveKey="home"
          id=""
          bsPrefix="mmbrshipVotingCreateHeader"
          className="mb-3"
          onSelect={setDefault}
        >
          <Tab eventKey="home" title="CREATE" tabClassName="createTab active">
            <div className="createBody">
              <form className="bodyContainerGA">
                <div className="createRow">
                  <h6>Title :</h6>
                  <input
                    type="text"
                    id="total-shares"
                    placeholder="Title"
                    name="titile"
                    value={title}
                    onChange={(e) => validateField(e)}
                  />
                </div>

                <div className="createRow">
                  <h6>Question :</h6>
                  <input
                    type="text"
                    id="total-shares"
                    name="question"
                    value={question}
                    placeholder="Question"
                    onChange={(e) => validateField(e)}
                  />
                </div>
                <div className="createRow">
                  <h6>Option 1 :</h6>
                  <input
                    className=""
                    type="text"
                    id="total-shares"
                    placeholder="Option 1"
                    value={option1}
                    name="option1"
                    onChange={(e) => validateField(e)}
                  />
                </div>
                <div className="createRow">
                  <h6>Option 2 :</h6>
                  <input
                    className=""
                    type="text"
                    id="total-shares"
                    placeholder="Option 2"
                    value={option2}
                    name="option2"
                    onChange={(e) => validateField(e)}
                  />
                </div>
                <div className="createRow">
                  <h6>Option 3 :</h6>
                  <input
                    className=""
                    type="text"
                    id="total-shares"
                    placeholder="Option 3"
                    value={option3}
                    name="option3"
                    onChange={(e) => validateField(e)}
                  />
                </div>
                <div className="createRow">
                  <h6>Option 4 :</h6>
                  <input
                    className=""
                    type="text"
                    id="total-shares"
                    placeholder="Option 4"
                    value={option4}
                    name="option4"
                    onChange={(e) => validateField(e)}
                  />
                </div>
                <div className="createRow">
                  <h6>Time Period:</h6>
                  <input
                    type="date"
                    id="total-shares"
                    placeholder=""
                    name="timePeriod"
                    value={timePeriod}
                    onChange={(e) => validateField(e)}
                  />
                </div>
                <div className="mbrshipVotingCreateFooter">
                  <Button className="caskGreenBtn" onClick={handleSubmit}>
                    SUBMIT
                  </Button>
                  <Button className="caskRedBtn" onClick={setDefault}>
                    CLEAR
                  </Button>
                </div>
              </form>
            </div>
          </Tab>
        </Tabs>
        <div className="createPollImg">
          <img src={walletImage} alt="" />
        </div>
      </section>
    </>
  );
};
export default GAMembershipVotingCreatePoll;
