import React, { useState, useEffect } from "react";
import { getToken, getUserData } from "../../../utils/helperFunctions";
import authenticationManagementAPIs from "../../../services/authentication.service";
import "./index.scss";
import icon1 from "../../../assets/images/option1_icon.svg";
import icon2 from "../../../assets/images/option2_icon.svg";
import icon3 from "../../../assets/images/option3_icon.svg";
import icon4 from "../../../assets/images/option4_icon.svg";
import upload from "../../../assets/images/option_icon.svg";
import voteImage from "./images/vote.png";
import { Splide, SplideSlide } from "@splidejs/react-splide";
const GMPollView = () => {
  const [membershipVotingDatas, setMembershipVotingDatas] = useState("");

  const fetchMembershipVotingData = async () => {
    const token = getToken();
    const userData = getUserData();
    const user_id = JSON.stringify(userData.user_id);
    const formData = new FormData();
    formData.append("user_id", user_id);
    const responseData =
      await authenticationManagementAPIs.MembershipVotingGroupMember(
        token,
        formData
      );
    if (responseData) {
      setMembershipVotingDatas(responseData.data);
    }
  };
  const updateMembershipVoting = async (selected, votingId) => {
    const selectedOption = selected;

    const token = getToken();
    const userData = getUserData();
    const user_id = JSON.stringify(userData.user_id);
    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("selectedOption", selectedOption);
    formData.append("membership_voting_id", JSON.stringify(votingId));
    const responseData =
      await authenticationManagementAPIs.MembershipVotingUpdateGroupMember(
        token,
        formData
      );

    if (responseData) {
      fetchMembershipVotingData();
    }
  };
  useEffect(() => {
    fetchMembershipVotingData();
  }, []);

  return (
    <>
      {membershipVotingDatas && (
        <div className="membershipVotingMainDiv">
          <div className="votingContainer">
            <Splide aria-label="Membership Voting">
              {membershipVotingDatas.map((membershipVotingData, index) => {
                return (
                  <SplideSlide key={index}>
                    <div className="row d-flex align-items-center">
                      <div className="row">
                        <div className="headtext">
                          <h4>{membershipVotingData.question}</h4>
                        </div>
                      </div>
                      <div className="row voting">
                        <div className="votingpanel">
                          <div className="row barchart">
                            <div className="row contents">
                              <div className="col-3 iconsfull">
                                <div className="icons">
                                  <img src={icon1} alt="Option 1" />
                                  {membershipVotingData.option1}
                                </div>
                                <div className="icons">
                                  <img src={icon2} alt="Option 2" />
                                  {membershipVotingData.option2}
                                </div>
                                <div className="icons">
                                  <img src={icon3} alt="Option 3" />
                                  {membershipVotingData.option3}
                                </div>
                                <div className="icons">
                                  <img src={icon4} alt="Option 4" />
                                  {membershipVotingData.option4}
                                </div>
                              </div>
                              {/* <div className="col-9 chartfull"> */}
                              <div
                                className={
                                  membershipVotingData.option_selected
                                    ? "col-9 chartfull"
                                    : " col-9 chartfullBlur"
                                }
                              >
                                <div className="chart">
                                  <div
                                    className="bar1"
                                    style={{
                                      height:
                                        (membershipVotingData.option1_count /
                                          (membershipVotingData.option1_count +
                                            membershipVotingData.option2_count +
                                            membershipVotingData.option3_count +
                                            membershipVotingData.option4_count)) *
                                        100,
                                    }}
                                  ></div>
                                  <div
                                    className="bar2"
                                    style={{
                                      height:
                                        (membershipVotingData.option2_count /
                                          (membershipVotingData.option1_count +
                                            membershipVotingData.option2_count +
                                            membershipVotingData.option3_count +
                                            membershipVotingData.option4_count)) *
                                        100,
                                    }}
                                  ></div>
                                  <div
                                    className="bar3"
                                    style={{
                                      height:
                                        (membershipVotingData.option3_count /
                                          (membershipVotingData.option1_count +
                                            membershipVotingData.option2_count +
                                            membershipVotingData.option3_count +
                                            membershipVotingData.option4_count)) *
                                        100,
                                    }}
                                  ></div>
                                  <div
                                    className="bar4"
                                    style={{
                                      height:
                                        (membershipVotingData.option4_count /
                                          (membershipVotingData.option1_count +
                                            membershipVotingData.option2_count +
                                            membershipVotingData.option3_count +
                                            membershipVotingData.option4_count)) *
                                        100,
                                    }}
                                  ></div>
                                </div>
                                <div className="voteMain">
                                  <div className="date">
                                    {membershipVotingData.option1_count} Votes
                                  </div>
                                  <div className="date">
                                    {membershipVotingData.option2_count} Votes
                                  </div>
                                  <div className="date">
                                    {membershipVotingData.option3_count} Votes
                                  </div>
                                  <div className="date">
                                    {membershipVotingData.option4_count} Votes
                                  </div>
                                </div>
                              </div>
                            </div>
                            {membershipVotingData.option_selected == "" && (
                              <div className="row choose">
                                <h5>
                                  Please choose an option below to see the
                                  voting results
                                </h5>
                              </div>
                            )}
                          </div>
                          <div className=" buttonsfull">
                            <div
                              className={
                                membershipVotingData.option_selected === ""
                                  ? "options"
                                  : "optionDisable"
                              }
                            >
                              <button
                                className={
                                  membershipVotingData.option_selected ===
                                  "option1"
                                    ? "buttonSelected"
                                    : "buttonVoting"
                                }
                                onClick={() =>
                                  updateMembershipVoting(
                                    "option1",
                                    membershipVotingData.membership_voting_id
                                  )
                                }
                              >
                                <div className="round">
                                  <img src={upload} alt="upload" />
                                </div>
                                {membershipVotingData.option1}
                              </button>
                            </div>
                            <div
                              className={
                                membershipVotingData.option_selected === ""
                                  ? "options"
                                  : "optionDisable"
                              }
                            >
                              <button
                                className={
                                  membershipVotingData.option_selected ===
                                  "option2"
                                    ? "buttonSelected"
                                    : "buttonVoting"
                                }
                                onClick={() =>
                                  updateMembershipVoting(
                                    "option2",
                                    membershipVotingData.membership_voting_id
                                  )
                                }
                              >
                                <div className="round1">
                                  <img src={upload} alt="upload" />
                                </div>
                                {membershipVotingData.option2}
                              </button>
                            </div>
                          </div>
                          <div className=" buttonsfull">
                            <div
                              className={
                                membershipVotingData.option_selected === ""
                                  ? "options"
                                  : "optionDisable"
                              }
                            >
                              <button
                                className={
                                  membershipVotingData.option_selected ===
                                  "option3"
                                    ? "buttonSelected"
                                    : "buttonVoting"
                                }
                                onClick={(e) =>
                                  updateMembershipVoting(
                                    "option3",
                                    membershipVotingData.membership_voting_id
                                  )
                                }
                              >
                                <div className="round2">
                                  <img src={upload} alt="upload" />
                                </div>
                                {membershipVotingData.option3}
                              </button>
                            </div>
                            <div
                              className={
                                membershipVotingData.option_selected === ""
                                  ? "options"
                                  : "optionDisable"
                              }
                            >
                              <button
                                className={
                                  membershipVotingData.option_selected ===
                                  "option4"
                                    ? "buttonSelected"
                                    : "buttonVoting"
                                }
                                onClick={() =>
                                  updateMembershipVoting(
                                    "option4",
                                    membershipVotingData.membership_voting_id
                                  )
                                }
                              >
                                <div className="round3">
                                  <img src={upload} alt="upload" />
                                </div>
                                {membershipVotingData.option4}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SplideSlide>
                );
              })}
            </Splide>
          </div>
          <div className="votingMain">
            <img src={voteImage} alt="Voting Image" />
          </div>
        </div>
      )}
    </>
  );
};
export default GMPollView;
