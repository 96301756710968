import React, { useEffect, useState } from "react";
import "./index.scss";
import SideImage from "../../../assets/images/withdraw-funds.png";
import {
  PriceComponent,
  getToken,
  getUserData,
} from "../../../utils/helperFunctions";
import getEndpoints from "../../../../src/config/endpoints";
import axios from "axios";
import ConfirmationPromptModal from "../../../components/Shared/ConfirmationBox/confirmationPrompt";
import ProfileCard from "../../../components/Shared/ProfileCard/ProfileCard";
import leftIcon from "../../../assets/images/burgerIconLeft.svg";
import RightIcon from "../../../assets/images/burgerIconRight.svg";
import { useSelector, useDispatch } from "react-redux";
import { show, hide, sidebarStatus } from "../../../store/sidebar";

const BrokerWalletWithdraw = () => {
  const openSidebar = useSelector(sidebarStatus);
  const dispatch = useDispatch();
  const [val, setVal] = useState(0);
  const [walletData, setWalletData] = useState(null);
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [showModal, setShowModal] = useState(false);

  const profileData = getUserData();
  const walletInfo = async () => {
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    try {
      const endpoint = getEndpoints().wallet.walletInfo;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      setWalletData(response.data[0]);
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  const withdrawInputValidator = (val) => {
    var pattern = /^\d+\.?\d*$/;
    if (pattern.test(val) == true) {
      setWithdrawAmount(val);
    }
  };
  const withdrawHandler = () => {
    if (withdrawAmount !== "") {
      setShowModal(false);
      withdrawAmountHandler();
    }
  };
  const withdrawAmountHandler = async () => {
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    formData.append("withdraw_amount", withdrawAmount);

    try {
      const endpoint = getEndpoints().wallet.withdraw;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      setVal(val + 1);
      setShowModal(false);
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  const triggerModalHide = () => {
    setShowModal(false);
  };
  useEffect(() => {
    walletInfo();
  }, [val]);
  return (
    <>
      <ConfirmationPromptModal
        show={showModal}
        confirmFunc={withdrawHandler}
        close={triggerModalHide}
      />
      <div className="mainContainerOuter">
        <main
          id="mainDashboard"
          className={openSidebar ? "main" : "mainContainer"}
        >
          <div className="row">
            <div className="pagetitle">
              <div className="sideBarIconMain">
                {openSidebar == true && (
                  <button
                    onClick={() => dispatch(hide())}
                    className="leftButton"
                  >
                    <img src={leftIcon} />
                  </button>
                )}
                {openSidebar == false && (
                  <button
                    onClick={() => dispatch(show())}
                    className="rightButton"
                  >
                    <img src={RightIcon} />
                  </button>
                )}
              </div>
              <h1 className="mainHeadingSub">Withdraw Funds</h1>
              <nav className="SubHeading">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/broker/wallet">{"< Back to Wallet"}</a>
                  </li>
                </ol>
              </nav>
            </div>
            <ProfileCard />
          </div>
          <section className="wallet">
            <div className="withDrawMain">
              <div className="withdraw">
                <div className="withdrawRow">
                  <div className="withdrawAmount">
                    <label>Select Amount</label>
                    <input
                      type="text"
                      className="card-input"
                      placeholder="£10,000.00"
                      id="amt"
                      value={withdrawAmount}
                      onChange={(e) => withdrawInputValidator(e.target.value)}
                    />
                  </div>
                  <div className="balance">
                    <div>
                      <p>Withdrawable Balance</p>
                      <h5>
                        {walletData.available_amt ? "£ " : ""}
                        {walletData && PriceComponent(walletData.available_amt)}
                      </h5>
                    </div>
                    <div>
                      <p>Total Balance</p>
                      <h5>
                        {walletData.total_amt ? "£ " : ""}
                        {walletData && PriceComponent(walletData.total_amt)}
                      </h5>
                    </div>
                  </div>
                </div>
                <div className="withdrawRow">
                  <div className="total">
                    <div className="totalRow">
                      <div className="totalBody">
                        <p>Cash Equity</p>
                        <span>
                          Withdrawable amount upon closing all Cask Sales
                        </span>
                      </div>
                      <h5>
                        {walletData &&
                        walletData.total_amt - walletData.pending_amount
                          ? "£ "
                          : ""}
                        {walletData &&
                          PriceComponent(
                            walletData.total_amt - walletData.pending_amount
                          )}
                      </h5>
                    </div>
                    <div className="totalRow">
                      <div className="totalBody">
                        <p>Pending Funds</p>
                        <span>
                          Non-withdrawable amount due on pending Cask Sales
                        </span>
                      </div>
                      <h5>
                        {walletData && walletData.pending_amount ? "£ " : ""}
                        {walletData &&
                          PriceComponent(walletData.pending_amount)}
                      </h5>
                    </div>
                    <div className="totalRow">
                      <p>Total Balance</p>
                      <h5>
                        {walletData && walletData.total_amt ? "£ " : ""}
                        {walletData && PriceComponent(walletData.total_amt)}
                      </h5>
                    </div>
                  </div>
                  <div className="withdrawBtn">
                    <button onClick={() => setShowModal(true)}>SUBMIT</button>
                  </div>
                </div>
                <div className="withdrawPolicy">
                  <h5>WITHDRAWAL POLICY</h5>
                  <p>
                    By submitting this withdrawal request I confirm I have read
                    and understood BitCask's
                    <b>Withdrawal Policy and Fees</b> and <b>Credit Policy</b>
                  </p>
                </div>
              </div>
              <div className="withdrawImg">
                <img src={SideImage} alt="" />
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

export default BrokerWalletWithdraw;
