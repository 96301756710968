const getEndpoints = () => {
  const baseUrl = "https://token.bit-cask.co.uk";
  const stripeWebApiUrl = "http://localhost:9080";

  const endpoints = {
    authentication: {
      login: `${baseUrl}/api/login`,
      forgotPassword: `${baseUrl}/api/forgot_password`,
      resetPassword: `${baseUrl}/api/reset_password`,
    },
    editProfile: `${baseUrl}/api/edit_profile`,
    inventoryDashboard: {},
    brokerPortfolio: {
      portfolio: `${baseUrl}/api/casklist`,
    },
    cask: {
      upload: `${baseUrl}/api/upload_cask`,
      request: `${baseUrl}/api/cask_request`,
    },
    users: {
      user_types: `${baseUrl}/api/get_user_type`,
      type_users_emails: `${baseUrl}/api/user_type_email`,
      parents: `${baseUrl}/api/super_admin_select_user_parent_View`,
      create_user: `${baseUrl}/api/super_admin_create_new_user_View`,
      all_type_user_emails: `${baseUrl}/api/super_admin_get_user_type_name_email_View`,
      fech_user_details: `${baseUrl}/api/stripe_fetch_user_details_View`,
    },
    createAccount: {
      broker: `${baseUrl}/api/update`,
      groupmember: `${baseUrl}/api/update`,
    },
    wallet: {
      walletInfo: `${baseUrl}/api/wallet_details`,
      deposit: `${baseUrl}/api/wallet_deposit`,
      withdraw: `${baseUrl}/api/wallet_withdraw`,
    },
    transactions: {
      transaction: `${baseUrl}/api/broker_transactions`,
      invoice: `${baseUrl}/api/broker_invoice`,
    },
    caskRequest: {
      individual: `${baseUrl}/api/GA_cask_request`,
      surpriseMe: `${baseUrl}/api/GA_cask_request_surprise_me`,
    },
    caskType: {
      fetch: `${baseUrl}/api/get_cask_types`,
    },
    groupadmin: {
      memberlist: `${baseUrl}/api/members_list`,
      portfolio: `${baseUrl}/api/group_admin_casklist`,
      market: {
        public: `${baseUrl}/api/GA_public_marketplace`,
        private: `${baseUrl}/api/GA_private_marketplace`,
        private_multiple: `${baseUrl}/api/GA_multiselect_private_marketplace`,
      },
      upload: {
        certificate: `${baseUrl}/api/portfolio_certificate`,
      },
      delete: {
        certificate: `${baseUrl}/api/delete_title_deed_certificate`,
      },
      cask: {
        sell: `${baseUrl}/api/GA_sell_cask`,
        buy: `${baseUrl}/api/GA_buy_cask`,
        buy_multiple: `${baseUrl}/api/GA_buy_multi_cask`,
      },
      membershipVoting: {
        create: `${baseUrl}/api/GA_create_voting`,
        view: `${baseUrl}/api/GA_view_voting`,
        delete: `${baseUrl}/api/GA_delete_voting`,
      },
      member: {
        add: `${baseUrl}/api/GA_add_member`,
        delete: `${baseUrl}/api/GA_delete_member`,
        view: `${baseUrl}/api/GA_view_voting`,
        popup_trigger: `${baseUrl}/api/add_member`,
      },
      transaction: `${baseUrl}/api/GA_transaction`,
      invoice: `${baseUrl}/api/GA_invoice`,
    },
    groupmember: {
      portfolio: `${baseUrl}/api/group_member_portfolio`,
      marketplace: {
        public: `${baseUrl}/api/group_member_public_marketplace`,
        updatelike: `${baseUrl}/api/like_update_public_marketplace`,
        private: `${baseUrl}/api/group_member_private_marketplace`,
      },
      cask: {
        buy: `${baseUrl}/api/groupmember_buy_cask`,
      },
      transaction: `${baseUrl}/api/groupmember_transaction`,
      membershipVoting: {
        view: `${baseUrl}/api/groupMember_membershipvoting`,
        updateVote: `${baseUrl}/api/groupmember_voting_update`,
      },
    },
    superAdmin: {
      payOut: {
        view: `${baseUrl}/api/super_admin_payout_View`,
        action: `${baseUrl}/api/Super_admin_payout_confirmed_denied_View`,
      },
      userPortfolio: {
        view: `${baseUrl}/api/super_admin_get_user_portfolio_View`,
        upload: `${baseUrl}/api/Super_admin_upload_portfolio_View`,
      },
    },
    invoice: `${baseUrl}/api/GA_invoice`,
    stripe: {
      createConnectAccount: `${stripeWebApiUrl}/connected_account`,
      payouts: `${stripeWebApiUrl}/payouts`,
      add_bank: `${stripeWebApiUrl}/add_account`,
    },
  };
  return endpoints;
};

export default getEndpoints;
