import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Logo from "../../../assets/images/logo.png";
import { PriceComponent, getUserData } from "../../../utils/helperFunctions";
import "./index.scss";
const InvoiceGM = (props) => {
  const profileData = getUserData();
  let [showModal, setShowModal] = useState(props.setDisplay);
  const invoiceSet = props.invoiceData;
  const setHide = props.onHide;
  const [subTotal, setSubTotal] = useState(0);
  const [transactionFee, setTransactionFee] = useState(0);
  const [platformFee, setPlatformFee] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const userData=props.userData;
  const amountData = () => {
    if (invoiceSet !== null) {
      setSubTotal(invoiceSet.shares_price);
      setPlatformFee(subTotal * (5 / 100)); //set 5% platform fee
      setTransactionFee(subTotal * (5 / 100)); //set 5% transaction fee
      setTotalAmount(subTotal + platformFee + transactionFee);
    }
  };

  useEffect(() => {
    setShowModal(props.setDisplay);
    amountData();
  }, [props, subTotal]);

  return (
    <>
      <Modal show={showModal}>
        <Modal.Body bsPrefix="invoiceGM">
          <div className="invoiceMainGM">
            <div className="invoiceHeadGM">
              <div className="logoInvoiceGM">
                <img src={Logo} className="img-fluid" />
              </div>
              <div className="address">
                <h6>Bit Cask</h6>
                <p>160 Robinson Road, #14-04</p>
                <p>Singapore Business Federation Centre</p>
                <p>Singapore</p>
                <p>068914</p>
              </div>
            </div>
            <div className="invoiceBodyGM">
              <div className="invoice-details1">
                <h4>INVOICE</h4>
              </div>
              <div className="invoice-details">
                <div className="invoice-left">
                  <div className="invoice-content">
                    <h6>Paid By :</h6>
                    <p>
                      {userData.first_name &&
                        userData.last_name &&
                        userData.first_name.charAt(0).toUpperCase() +
                          userData.first_name.slice(1) +
                          " " +
                          userData.last_name.charAt(0).toUpperCase() +
                          userData.last_name.slice(1)}
                    </p>
                  </div>
                  <div className="invoice-content">
                    <h6>Address :</h6>
                    <p>10 Dover Drive Singapore 138683</p>
                  </div>
                </div>
                <div className="invoice-center">
                  <div className="invoice-content">
                    <h6>Invoice Number :</h6>
                    <p>{invoiceSet && invoiceSet.invoice_no}</p>
                  </div>
                </div>
                <div className="invoice-right">
                  <div className="invoice-content1">
                    <h6>Date :</h6>
                    <p>{invoiceSet && invoiceSet.transaction_date}</p>
                  </div>
                  <div className="invoice-content1">
                    <h6>Payment Method :</h6>

                    <p>STRIPE</p>
                  </div>
                </div>
              </div>
              <div className="invoice-bill">
                <div className="invoice-table-container">
                  <table className="invoice-table">
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Share</th>
                        <th>Price</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {invoiceSet && (
                        <tr>
                          <td>
                            {invoiceSet.distillery &&
                              invoiceSet.distillery +
                                ", " +
                                invoiceSet.casktype}
                            <div className="description">
                              {invoiceSet.casktype && "Entire Cask"}
                            </div>
                          </td>
                          <td>
                            {invoiceSet.ordered_shares &&
                              invoiceSet.ordered_shares}
                          </td>

                          <td>
                            {invoiceSet.shares_price &&
                              "£" + PriceComponent(invoiceSet.shares_price)}
                            <div className="description1">
                              {invoiceSet.casktype_sell}
                            </div>
                          </td>
                          <td>
                            {" "}
                            {invoiceSet.shares_price &&
                              " £" +
                                PriceComponent(invoiceSet.shares_price)}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <img src={Logo} alt="" className="invoice-logo" />
                </div>
                <div className="invoice-total">
                  <div>
                    <p>Subtotal</p>
                    <h6>£{PriceComponent(subTotal)}</h6>
                  </div>
                  <div>
                    <p>Platform Fee (5%)</p>
                    <h6>£{PriceComponent(platformFee)}</h6>
                  </div>
                  <div>
                    <p>Transaction Fee (5%)</p>
                    <h6>£{PriceComponent(transactionFee)}</h6>
                  </div>
                  <div>
                    <p>Discount</p>
                    <h6>£0.00</h6>
                  </div>
                  <div>
                    <p>TOTAL AMOUNT</p>
                    <h6>
                      £{invoiceSet && PriceComponent(invoiceSet.total_price)}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="invoice-footer">
              <button className="red-btn" onClick={() => setHide()}>
                Close
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default InvoiceGM;
