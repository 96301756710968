import React, { useEffect, useState } from "react";
import getEndpoints from "../../../../src/config/endpoints";
import axios from "axios";
import CaskRequestModal from "./caskRequestModal";
import Button from "react-bootstrap/Button";
import { PriceComponent } from "../../../utils/helperFunctions";

const CaskRequest = () => {
  const [caskRequestList, setCaskRequestList] = useState([]);
  const [caskModalShow, setCaskModalShow] = useState(false);
  const [caskData, setCaskData] = useState([]);
  const profileData = JSON.parse(localStorage.getItem("userData"));
  const loadCaskRequestHandler = async () => {
    let formData = new FormData();
    formData.append("user_id", profileData.user_id);
    try {
      const endpoint = getEndpoints().cask.request;
      const response = await axios.post(endpoint, formData, {
        headers: { token: profileData.token },
      });
      setCaskRequestList(response.data);
      return response;
    } catch (e) {
      return e.response || e;
    }
  };
  const ModalShowHandler = () => {
    setCaskModalShow(!caskModalShow);
  };
  const triggerdDetailedView = (event) => {
    ModalShowHandler();
    setCaskData(caskRequestList[event.target.value]);
  };

  useEffect(() => {
    loadCaskRequestHandler();
  }, []);

  return (
    <>
      <CaskRequestModal
        data={caskData}
        show={caskModalShow}
        onHide={ModalShowHandler}
      />
      <div>
        <table>
          <thead className="table-head">
            <tr>
              <th scope="col">NAME</th>
              <th scope="col">TYPE</th>
              <th scope="col">BUDGET</th>
              <th scope="col">PURPOSE</th>
              <th scope="col">SURPRISE ME</th>
              <th scope="col">DETAILS</th>
            </tr>
          </thead>
          <tbody className="table-body">
            {caskRequestList &&
              caskRequestList.map((cask, index) => {
                return (
                  <tr key={index}>
                    <td data-label="NAME">
                      {cask.first_name + " " + cask.last_name}
                    </td>
                    <td data-label="TYPE">{cask.type}</td>
                    <td data-label="BUDGET">£{PriceComponent(cask.budget)}</td>
                    <td data-label="PURPOSE">{cask.purpose}</td>
                    <td data-label="SURPRISE">{cask.surprise_me}</td>
                    <td data-label="DETAILS">
                      <Button
                        className="greenButtonMain"
                        value={index}
                        onClick={triggerdDetailedView}
                      >
                        VIEW
                      </Button>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default CaskRequest;
