import React, { useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Auth from "../components/Auth";
import GrpMemberDashboard from "../pages/GroupMember/GroupMemberDashboard";
import GMTransactionsMain from "../pages/GroupMember/GroupMemberTransaction";
import GMMarketPublicMain from "../pages/GroupMember/GroupMemberPublicMarket";
import GMMarketPrivateMain from "../pages/GroupMember/GroupMemberPrivateMarket";
import GroupMemberBuyCask from "../pages/GroupMember/GroupMemberBuyCask";
import GMvoting from "../pages/GroupMember/GroupMemberVoting";
import GrpMmberSideBar from "../components/GrpMmberSideBar";
const GroupMemberRoutes = () => {
  const location = useLocation();
  const [openSidebar, setOpenSidebar] = useState(true);

  return (
    <>
      <GrpMmberSideBar openSidebar={openSidebar} />
      <Routes>
        <Route
          path="/"
          element={
            <Auth>
              <GrpMemberDashboard />
            </Auth>
          }
        />

        {/* Wallet Routes */}

        <Route
          path="/transactions"
          element={
            <Auth>
              <GMTransactionsMain />
            </Auth>
          }
        />
        <Route
          path="/marketplace/public"
          element={
            <Auth>
              <GMMarketPublicMain />
            </Auth>
          }
        />
        <Route
          path="/marketplace/private"
          element={
            <Auth>
              <GMMarketPrivateMain />
            </Auth>
          }
        />
        <Route
          path="/voting"
          element={
            <Auth>
              <GMvoting />
            </Auth>
          }
        />
        <Route
          path="/marketplace/buycask/private"
          element={
            location.state !== null ? (
              <Auth>
                <GroupMemberBuyCask />
              </Auth>
            ) : (
              <Navigate replace to={"/groupmember/"} />
            )
          }
        />
        <Route exact path="*" element={<h1>Page Not Found</h1>} />
      </Routes>
    </>
  );
};

export default GroupMemberRoutes;
